import createState from '@frontend/store/showcase/common/cart/state'
import { RESET_STATE } from 'Helpers/ResetState'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setInvoices(state, invoices) {
    state.invoices = invoices
  },

  setTheInvoice(state, payload) {
    state.invoice = payload
  },

  setTheInvoicePaymentUrl(state, payload) {
    state.paymentUrl = payload
  },

  setPurchasedProduct(state, payload) {
    state.purchasedProduct = payload
  },
}
