export const clientMainRoutes = [
  {
    path: 'profile',
    name: 'client-profile',
    component: () =>
      import(
        /* webpackChunkName: "client-profile" */
        '@frontend/pages/showcase/client/Profile.vue'
      ),
  },

  {
    path: 'online-lessons',
    name: 'client-showcase-online-lessons',
    component: () =>
      import(
        /* webpackChunkName: "showcase-home" */
        '@frontend/pages/showcase/common/LessonsOnline.vue'
      ),
  },

  {
    path: 'invoices',
    name: 'client-showcase-invoices',
    component: () =>
      import(
        /* webpackChunkName: "showcase-invoices" */
        '@frontend/pages/showcase/client/Invoices.vue'
      ),
  },

  {
    path: 'invoice/:invoiceId(\\d+)',
    name: 'client-showcase-the-invoice',
    component: () =>
      import(
        /* webpackChunkName: "showcase-invoice" */
        '@frontend/pages/showcase/client/Invoice.vue'
      ),
  },

  {
    path: 'payment/success',
    name: 'client-showcase-payment-success',
    component: () =>
      import(
        /* webpackChunkName: "showcase-invoice" */
        '@frontend/pages/showcase/client/Invoice.vue'
      ),
    props: {
      isModalOpenSuccess: true,
    },
  },

  {
    path: 'payment/error',
    name: 'client-showcase-payment-error',
    component: () =>
      import(
        /* webpackChunkName: "showcase-invoice" */
        '@frontend/pages/showcase/client/Invoice.vue'
      ),
    props: {
      isModalOpenError: true,
    },
  },

  {
    path: 'notifications',
    name: 'client-notifications',
    component: () =>
      import(
        /* webpackChunkName: "client-notifications" */
        '@frontend/pages/showcase/common/Notifications.vue'
      ),
  },
  {
    path: 'online-lessons/:lessonId(\\d+)',
    name: 'client-the-online-lesson',
    component: () =>
      import(
        /* webpackChunkName: "the-online-lesson" */
        '@frontend/pages/showcase/common/LessonsOnline.vue'
      ),
  },
]
