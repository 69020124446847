import { ApiIncomeDiagnosticsExtramuralAgreement } from 'ApiRest/Api/Account/Client/IncomeDiagnostics/Extramural/Agreement'
import { ApiIncomeDiagnosticsExtramuralHasAgreement } from 'ApiRest/Api/Account/Client/IncomeDiagnostics/Extramural/HasAgreement'
import { ApiDiagnosticsApplicationsHasRetake } from 'ApiRest/Api/Account/Client/IncomeDiagnostics/HasRetake'
import { ApiIncomeDiagnosticsRetakeAgreement } from 'ApiRest/Api/Account/Client/IncomeDiagnostics/Retake/Agreement'
import { ApiIncomeDiagnosticsRetakeList } from 'ApiRest/Api/Account/Client/IncomeDiagnostics/Retake/List'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchHasRetake({ commit, state }) {
    const { data } = await ApiDiagnosticsApplicationsHasRetake.get(
      state.applicationId,
    )

    commit('setHasRetakeAgreement', data)
  },

  async fetchRetakes({ commit, state }) {
    const { data } = await ApiIncomeDiagnosticsRetakeList.get(
      state.applicationId,
    )

    commit('setRetakes', data)
  },

  async downloadRetakeAgreement({ state }, retakeId) {
    const response = await ApiIncomeDiagnosticsRetakeAgreement.get(
      state.applicationId,
      retakeId,
    )

    DownloadHelper.downloadBlob(response)
  },

  async downloadExtramuralAgreement({ state }) {
    const response = await ApiIncomeDiagnosticsExtramuralAgreement.get(
      state.applicationId,
    )

    DownloadHelper.downloadBlob(response)
  },

  async fetchExtramuralHasAgreement({ commit, state }) {
    const { data } = await ApiIncomeDiagnosticsExtramuralHasAgreement.get(
      state.applicationId,
    )

    commit('setHasExtramuralAgreement', data)
  },
}
