export default {
  setApplicationId(state, payload) {
    state.applicationId = payload
  },

  setRetakes(state, payload) {
    state.retakes = payload
  },

  setHasExtramuralAgreement(state, payload) {
    state.hasExtramuralAgreement = payload
  },

  setHasRetakeAgreement(state, payload) {
    state.hasRetakeAgreement = payload
  },
}
