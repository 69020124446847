import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesMain = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {number} payload.cardsMax
   * @returns {Promise}
   */
  get(
    { cardsMax } = {
      cardsMax: 8,
    },
  ) {
    const url = `/courses/main?cardsMax=${cardsMax}`

    return this._GET(url)
  }
})()
