import StudentMain from '@frontend/layouts/StudentMain.vue'
import { studentCourseRoutes } from '@frontend/routes/student/studentCourseRoutes'
import { studentEducontRoutes } from '@frontend/routes/student/studentEducontRoutes'
import { studentMainRoutes } from '@frontend/routes/student/studentMainRoutes'
import { studentSubjectRoutes } from '@frontend/routes/student/studentSubjectRoutes'
import { studentSupportRoutes } from '@frontend/routes/student/studentSupportRoutes'
import { studentTestRoutes } from '@frontend/routes/student/studentTestRoutes'
import UserStore from '@frontend/UserStore'

const checkRole = (to, from, next) => {
  const isStudent = UserStore.store.getters['common/user/isStudent']

  if (isStudent) {
    next()
  } else {
    next({
      name: 'login',
    })
  }
}

export const studentRoutes = [
  {
    path: '/student',
    component: StudentMain,
    children: [
      ...studentCourseRoutes,
      ...studentEducontRoutes,
      ...studentMainRoutes,
      ...studentSubjectRoutes,
      ...studentSupportRoutes,
    ],
  },

  ...studentTestRoutes,

  {
    path: '/student/online-lessons/preview',
    name: 'student-the-online-lesson-preview',
    component: () =>
      import(
        /* webpackChunkName: "student-the-online-lesson-preview" */ '@frontend/pages/student/TheLessonOnlinePreview.vue'
      ),
  },
]

studentRoutes.forEach((route) => {
  route.beforeEnter = checkRole
})
