import Constants from 'Constants'
import { feature } from 'Helpers/feature'

export default {
  hasEnrolledStudents(state, getters) {
    return getters.enrolledStudents.length > 0
  },

  enrolledStudents(state) {
    return state.students.filter(
      (student) =>
        student?.accountStatus.id === Constants.accountStatus.ACTIVE &&
        (student?.enrollmentStatus.id === Constants.enrollmentStatus.ENROLLED ||
          student?.hasIncomeDiagnostics),
    )
  },

  schoolPolicyFileUrl(state) {
    return state.school?.policyFile?.fileUrl ?? ''
  },

  schoolPDFileUrl(state) {
    return state.school?.personalDataFile?.fileUrl
  },

  /**
   * @param {Object} state
   * @returns {Object}
   */
  lastMonthStat(state) {
    return state.analytics?.lastMonthStat || {}
  },

  currentStudentStreamAcademicYearPreset(state) {
    return state.currentStudent?.streamAcademicYearPreset
  },

  isCurrentStudentDemo(state) {
    return Boolean(state.currentStudent?.isDemo)
  },

  getCurrentStudentDemoAccessTime(state) {
    return Number(state.currentStudent?.demoAccessTime)
  },

  getCurrentStudentSchoolDemoMessage(state) {
    let demoMessage = ''

    switch (state.currentStudent?.educationType?.id) {
      case Constants.educationType.EXTRAMURAL:
        demoMessage = state.currentStudent?.school?.demoMessage
        break
      case Constants.educationType.FAMILY:
        demoMessage = state.currentStudent?.school?.demoMessageFe
        break

      default:
        break
    }

    return demoMessage
  },

  currentStudentId(state) {
    return state.currentStudent.id
  },

  educationType(state) {
    const { EXTRAMURAL, FAMILY, FULL_TIME } = Constants.educationType
    const id = state.currentStudent?.educationType?.id

    return {
      extramural: id === EXTRAMURAL,
      family: id === FAMILY,
      fullTime: id === FULL_TIME,
    }
  },

  isStudentEnrolledAndHasDiagnostics(state) {
    return (
      feature.isIncomeDiagnostics &&
      state.currentStudent?.enrollmentStatus?.id !==
        Constants.enrollmentStatus.ENROLLED &&
      state.currentStudent?.hasIncomeDiagnostics
    )
  },
}
