import ApiDownloadFile from 'Api/Download'
import { ApiCoursesPurchase } from 'ApiRest/Api/Courses/Purchase'
import { ApiCoursesPurchaseClientData } from 'ApiRest/Api/Courses/Purchase/ClientData'
import { ApiCoursesPurchaseGenerateInvoices } from 'ApiRest/Api/Courses/Purchase/GenerateInvoices'
import { ApiCoursesPurchaseItemDocument } from 'ApiRest/Api/Courses/Purchase/Item/document/PurchaseItemDocument'
import { ApiCoursesPurchases } from 'ApiRest/Api/Courses/Purchases'
import ApiOption from 'ApiRest/Api/Option'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchPurchases({ commit }) {
    const { data } = await ApiCoursesPurchases.get()

    commit('setPurchases', data)
  },

  async fetchPurchase({ commit, state }) {
    const { data } = await ApiCoursesPurchase.get(state.purchaseId)

    commit('setPurchase', data)
  },

  async fetchClientData({ commit, state }) {
    const { data } = await ApiCoursesPurchaseClientData.get(state.purchaseId)

    commit('setClientData', data)
  },

  async updateClientData({ commit, state }) {
    const { data } = await ApiCoursesPurchaseClientData.patch(
      state.purchaseId,
      state.customer,
    )

    commit('setClientData', data)
  },

  async fetchOptionCitizenshipList({ commit }) {
    const { data } = await ApiOption.getCitizenships()

    commit('setOptionValue', {
      path: 'citizenshipList',
      value: data,
    })
  },

  async fetchOptionCountryList({ commit }) {
    const { data } = await ApiOption.getCountries()

    commit('setOptionValue', {
      path: 'countryList',
      value: data,
    })
  },

  async fetchPurchaseItemDocument(
    context,
    { purchaseId, purchaseItemId, documentTemplateId },
  ) {
    const response = await ApiCoursesPurchaseItemDocument.get({
      purchaseId,
      purchaseItemId,
      documentTemplateId,
    })

    DownloadHelper.downloadBlob(response)
  },

  fetchSignedDocumentUrl(context, fileId) {
    return ApiDownloadFile.getFileUrl(fileId)
  },

  async createInvoice({ state, commit }) {
    const { data } = await ApiCoursesPurchaseGenerateInvoices.put(
      state.purchaseId,
    )

    commit('setInvoices', data)
  },
}
