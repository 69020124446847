import { getModuleSettingValue, hasModules } from 'Helpers/accessControl/module'

export default {
  hasModules(state) {
    return (moduleIdsSearched) =>
      hasModules(state.activeModules, moduleIdsSearched)
  },

  hasModuleSettingValue(state, getters) {
    return ({ moduleId, settingId, settingValue }) => {
      if (!getters.hasModules([moduleId])) {
        return false
      }

      const moduleSettingValue = getters.moduleSettingValue(moduleId, settingId)

      if (moduleSettingValue) {
        for (const educationType of Object.values(moduleSettingValue)) {
          if (Number(educationType.id) === settingValue) {
            return true
          }
        }
      }

      return false
    }
  },

  moduleSettingValue(state) {
    return (moduleId, settingId) =>
      getModuleSettingValue({
        activeModules: state.activeModules,
        moduleId,
        settingId,
      })
  },
}
