const init = function () {
  const { host } = window.location
  const priorityDomains = JSON.parse(process.iblsEnv.IBLS_PRIORITY_DOMAINS)

  /**
   * @param {string} apiDomain
   * @returns {string}
   */
  const getApiUrl = (apiDomain) =>
    `${process.iblsEnv.IBLS_API_PROTOCOL}://${apiDomain}`

  priorityDomains.forEach((domain) => {
    const hosts = [
      domain.mobile,
      domain.main,
      domain.admin,
      domain.chat,
      domain.showcase,
    ]

    if (hosts.includes(host)) {
      window.domains = {
        mobile: domain.mobile,
        main: domain.main,
        admin: domain.admin,
        chat: domain.chat,
        showcase: domain.showcase,
        apiUrl: getApiUrl(domain.api),
        protocol: process.iblsEnv.IBLS_API_PROTOCOL ?? 'https',
      }

      /**
       * TODO: Костыль для https://redmine.ibls.tech/issues/1989
       * в .env необходимо добавить в IBLS_PRIORITY_DOMAINS "showcaseApiUrl":"api.ibls.courses"
       */
      if (window.location.host === domain.showcase && domain.showcaseApiUrl) {
        window.domains.apiUrl = getApiUrl(domain.showcaseApiUrl)
      }
      /**
       * Конец костыля
       */
    }
  })

  if (!window.domains) {
    let main

    if (process.iblsEnv.IBLS_MAIN_DOMAIN) {
      main = process.iblsEnv.IBLS_MAIN_DOMAIN
    } else {
      const parts = host.split('.')

      const isAdmin =
        parts[0] === process.iblsEnv.IBLS_ADMIN_SUBDOMAIN ||
        host === process.iblsEnv.IBLS_ADMIN_DOMAIN

      if (isAdmin) {
        parts.shift()
        main = parts.join('.')
      } else {
        main = host
      }
    }

    const mobile = process.iblsEnv.IBLS_MOBILE_DOMAIN
      ? process.iblsEnv.IBLS_MOBILE_DOMAIN
      : `${process.iblsEnv.IBLS_MOBILE_SUBDOMAIN}.${main}`

    const admin = process.iblsEnv.IBLS_ADMIN_DOMAIN
      ? process.iblsEnv.IBLS_ADMIN_DOMAIN
      : `${process.iblsEnv.IBLS_ADMIN_SUBDOMAIN}.${main}`

    const api = process.iblsEnv.IBLS_API_DOMAIN
      ? process.iblsEnv.IBLS_API_DOMAIN
      : `${process.iblsEnv.IBLS_API_SUBDOMAIN}.${main}`

    const chat = process.iblsEnv.IBLS_CHAT_DOMAIN
      ? process.iblsEnv.IBLS_CHAT_DOMAIN
      : `${process.iblsEnv.IBLS_CHAT_SUBDOMAIN}.${main}`

    const showcase = process.iblsEnv.IBLS_SHOWCASE_DOMAIN
      ? process.iblsEnv.IBLS_SHOWCASE_DOMAIN
      : `${process.iblsEnv.IBLS_SHOWCASE_SUBDOMAIN}.${main}`

    window.domains = {
      mobile,
      main,
      admin,
      chat,
      showcase,
      apiUrl: getApiUrl(api),
      protocol: process.iblsEnv.IBLS_API_PROTOCOL ?? 'https',
    }
  }
}

init()
