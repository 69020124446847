import UserRouter from '@frontend/UserRouter'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiClientTheInvoice from 'ApiRest/Api/Account/Client/Invoice'
import ApiCancelDiscount from 'ApiRest/Api/Account/Client/Invoice/CancelDiscount'
import { ApiClientInvoices } from 'ApiRest/Api/Account/Client/Invoice/List'
import ApiPaymentUrl from 'ApiRest/Api/Account/Client/Invoice/PaymentUrl'
import { ApiAccountInvoiceReceive } from 'ApiRest/Api/Account/Client/Invoice/Receive'
import Constants from 'Constants'
import Vue from 'vue'

import { i18n } from '@/i18n/setup'
import { eventBus } from '@/pubsub/bus'

const globalMessageOnApplyPromocode = function () {
  eventBus.$emit('frontend.client.promocode.applied')
}

export default {
  /**
   * Список счетов клиента
   *
   * @param {{commit}} context
   * @returns {Promise<void>}
   */
  async fetchInvoices({ commit }) {
    const params = {
      showcase: 0,
    }

    const { data } = await ApiClientInvoices.get(params)

    commit('setInvoices', data)
  },

  /**
   * Счет клиента
   *
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoice({ commit }, invoiceId) {
    try {
      const { data } = await ApiClientTheInvoice.get(invoiceId)

      commit('setTheInvoice', data)
    } catch (error) {
      // TODO: -> component
      if (error?.response?.status === HttpStatusCodes.NotFound) {
        Vue.toasted.show(
          i18n.t('store.client.toasted_message.account_not_found'),
          {
            type: 'error',
            duration: 3000,
          },
        )

        UserRouter.router.push({
          name: 'client-invoices',
        })
      }

      throw error
    }
  },

  async fetchPaymentUrl({ commit }, { invoiceId, parameters }) {
    const { data } = await ApiPaymentUrl.get(invoiceId, parameters)

    commit('setTheInvoicePaymentUrl', data)
  },

  /**
   * Ссылка на url счета
   *
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoicePaymentUrl({ dispatch }, invoiceId) {
    await dispatch('fetchPaymentUrl', {
      invoiceId,
      parameters: {
        driver: Constants.invoicePaymentDriver.TINKOFF,
      },
    })
  },

  /**
   * Ссылка на url счета Долями
   *
   * @param {{commit}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  async fetchTheInvoiceDolyameUrl({ dispatch }, invoiceId) {
    await dispatch('fetchPaymentUrl', {
      invoiceId,
      parameters: {
        driver: Constants.invoicePaymentDriver.DOLYAME,
      },
    })
  },

  /**
   * Применить промокод
   *
   * @param {Object} context
   * @param {Object} payload
   * @param {number} payload.invoiceId
   * @param {Object} payload.data
   * @returns {Promise<void>}
   */
  async applyPromocode(context, { invoiceId, data }) {
    await ApiClientTheInvoice.put(invoiceId, data)
    globalMessageOnApplyPromocode()
  },

  /**
   * Отменить промокод
   *
   * @param {{}} context
   * @param {number} invoiceId
   * @returns {Promise<void>}
   */
  discardPromocode(context, invoiceId) {
    return ApiCancelDiscount.put(invoiceId)
  },

  recieveFreeProduct(context, invoiceId) {
    return ApiAccountInvoiceReceive.post(invoiceId)
  },
}
