<template>
  <header
    ref="header"
    class="s-header-wrapper"
    :class="{ noticed: noticeText }"
  >
    <div v-if="noticeText" class="s-header__notice">
      <span>
        {{ noticeText }}

        <span class="notice-text--thin">{{ noticeAdditionalMessage }}</span>
      </span>
    </div>

    <div class="s-header" :class="classHeaderClient">
      <router-link class="s-logo s-header__logo" :to="toMain">
        <h2 class="s-school-name">{{ schoolName }}</h2>

        <img :src="imageLogo" :alt="$t('layout.common.logotype')" />
      </router-link>

      <div class="s-header__profile d-lg-none d-xl-flex">
        <ElAvatar :abbreviation="abbreviation" :image="avatar" shape="circle" />

        <span class="text">
          <span class="name">{{ fullName }}</span>
        </span>
      </div>

      <div v-if="students.length" class="s-header__student-select">
        <v-select
          :value="currentStudentFormatted"
          class="v-select-student"
          :options="studentsFormatted"
          :clearable="false"
          :searchable="false"
          label="abbr"
          @input="selectStudent"
        >
          <template #selected-option="{ abbr, name, gradeName, photo }">
            <div class="s-header__student">
              <ElAvatar :abbreviation="abbr" :image="getStudentAvatar(photo)" />

              <span class="text">
                <span class="name">{{ name }}</span>

                <span class="grade">{{ gradeName }}</span>
              </span>
            </div>
          </template>

          <template #option="{ name, gradeName }">
            <div class="s-header__student-option">
              <b class="name">{{ name }}</b>

              <span class="t-tag">{{ gradeName }}</span>
            </div>
          </template>
        </v-select>
      </div>

      <TheHeaderRating v-if="isRendered.rating" :rating="rating" />

      <TheHeaderCredit v-if="isRendered.credit" :rating="credit" />

      <nav class="s-header__nav">
        <router-link
          v-if="notifications"
          :to="{ name: 'client-notifications' }"
        >
          <img :src="icons.bell" :alt="$t('layout.common.notification_icon')" />

          <i v-if="Number(notifications.notifications) > 0" class="notif">
            {{ notifications.notifications }}
          </i>
        </router-link>

        <router-link v-if="calendar" :to="{ name: 'client-calendar' }">
          <img :src="icons.calendar" :alt="$t('layout.common.calendar_icon')" />

          <i v-if="Number(calendar.notifications) > 0" class="notif">
            {{ calendar.notifications }}
          </i>
        </router-link>

        <router-link
          v-if="messages"
          :to="{ name: 'client-messages' }"
          class="ml-20"
        >
          <img :src="icons.message" :alt="$t('layout.common.message_icon')" />

          <i v-if="Number(messages.notifications) > 0" class="notif">
            {{ messages.notifications }}
          </i>
        </router-link>

        <router-link v-if="hasInvoices" :to="invoicesLink" class="ml-20">
          <img :src="icons.payments" :alt="$t('layout.common.bill_icon')" />
        </router-link>

        <router-link :to="{ name: 'client-profile' }" class="wide">
          <img :src="icons.settings" :alt="$t('layout.common.settings_icon')" />
        </router-link>

        <router-link :to="{ name: 'logout' }" class="wide">
          <img :src="icons.exit" :alt="$t('layout.common.exit_icon')" />
        </router-link>
      </nav>

      <button
        class="s-mobile-menu__toggle"
        :class="{ opened }"
        @click="toggleMobileMenu"
      >
        <span v-for="i in 3" :key="i"></span>
      </button>

      <transition name="fade">
        <menu v-if="opened" class="s-mobile-menu" :style="mobileMenuTopOffset">
          <div class="block">
            <template v-for="link in links">
              <a
                v-if="link.externalUrl"
                :key="link.label"
                :class="link.class"
                :target="link.isExternal ? '_blank' : false"
                class="link-dark"
                :href="link.externalUrl"
                :rel="link.isExternal ? 'noopener noreferrer' : false"
              >
                <span>{{ $t(link.labelPath) }}</span>

                <i v-if="Number(link.notifications) > 0" class="notif">
                  {{ link.notifications }}
                </i>
              </a>

              <router-link
                v-else
                :key="link.link.name"
                :to="link.link"
                :class="link.class"
                :target="link.isExternal ? '_blank' : false"
                :rel="link.isExternal ? 'noopener noreferrer' : false"
                class="link-dark"
              >
                {{ link.label }}

                <i v-if="Number(link.notifications) > 0" class="notif">
                  {{ link.notifications }}
                </i>
              </router-link>
            </template>
          </div>

          <div class="block">
            <router-link :to="{ name: 'client-profile' }" class="link-dark">
              {{ $t('layout.common.settings') }}
            </router-link>

            <router-link :to="{ name: 'logout' }" class="link-dark">
              {{ $t('layout.common.log_off') }}
            </router-link>
          </div>
        </menu>
      </transition>
    </div>
  </header>
</template>

<script>
import TheHeaderCredit from '@frontend/components/common/TheHeaderCredit.vue'
import TheHeaderRating from '@frontend/components/common/TheHeaderRating.vue'
import ElAvatar from '@frontend/ui/ElAvatar.vue'
import HeaderMixin from 'Helpers/mixins/layout/HeaderMixin'
import { isEmpty, isNull } from 'lodash'
import bell from 'Static/images/@frontend/common-lk/icons/bell.svg'
import calendar from 'Static/images/@frontend/common-lk/icons/calendar.svg'
import exit from 'Static/images/@frontend/common-lk/icons/exit.svg'
import message from 'Static/images/@frontend/common-lk/icons/message.svg'
import payments from 'Static/images/@frontend/common-lk/icons/payments.svg'
import settings from 'Static/images/@frontend/common-lk/icons/settings.svg'
import imageLogo from 'Static/images/@frontend/common-lk/logo.svg'
import { mapGetters } from 'vuex'

export default {
  name: 'TheHeaderClient',

  components: {
    ElAvatar,
    TheHeaderRating,
    TheHeaderCredit,
  },

  mixins: [HeaderMixin],

  props: {
    links: {
      type: Array,
      required: true,
    },

    fullName: {
      type: String,
      default: '',
    },

    abbreviation: {
      type: String,
      default: '',
    },

    avatar: {
      type: String,
      default: '',
    },

    noticeText: {
      type: String,
      default: '',
    },

    students: {
      type: Array,
      default: () => [],
    },

    schoolName: {
      type: String,
      default: '',
    },

    currentStudent: {
      type: Object,
      default: null,
    },

    hasEnrolledStudents: {
      type: Boolean,
      required: true,
    },

    hasContractsOrDrafts: {
      type: Boolean,
      required: true,
    },

    noticeAdditionalMessage: {
      type: String,
      default: '',
    },

    rating: {
      type: Object,
      default: null,
    },

    credit: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      imageLogo,
      icons: {
        calendar,
        settings,
        message,
        payments,
        bell,
        exit,
      },
    }
  },

  computed: {
    ...mapGetters('client/invoices', ['hasInvoices']),

    ...mapGetters('client/students', ['isStudentEnrolledAndHasDiagnostics']),

    calendar() {
      return this.links.find((i) => i.link.name === 'client-calendar')
    },

    notifications() {
      return this.links.find((i) => i.link.name === 'client-notifications')
    },

    messages() {
      return this.links.find((i) => i.link.name === 'client-messages')
    },

    getAbbr() {
      return (lastName, firstName) =>
        (lastName ? lastName.slice(0, 1).toUpperCase() : '') +
        (firstName ? firstName.slice(0, 1).toUpperCase() : '')
    },

    getName() {
      return (lastName, firstName) =>
        (!isEmpty(firstName) ? firstName : '') +
        (lastName ? ` ${lastName[0].toUpperCase()}.` : '')
    },

    getGradeName() {
      return (gradeName) =>
        gradeName ? `${gradeName} ${this.$t('layout.common.grade_ls')}` : ''
    },

    studentsFormatted() {
      if (this.students.length) {
        return this.students.map((student) => {
          const abbr = this.getAbbr(student.lastName, student.firstName)
          const name = this.getName(student.lastName, student.firstName)
          const gradeName = !isNull(student.letter)
            ? this.getGradeName(`${student.grade?.name}-${student.letter}`)
            : this.getGradeName(student.grade?.name)

          return {
            ...student,
            abbr,
            name,
            gradeName,
          }
        })
      }

      return []
    },

    currentStudentFormatted() {
      if (this.currentStudent) {
        const { lastName, firstName, grade, letter } = this.currentStudent
        const abbr = this.getAbbr(lastName, firstName)
        const name = this.getName(lastName, firstName)

        const gradeName = !isNull(letter)
          ? this.getGradeName(`${grade.name}-${letter}`)
          : this.getGradeName(grade.name)

        return {
          ...this.currentStudent,
          abbr,
          name,
          gradeName,
        }
      }

      return {}
    },

    toMain() {
      if (!this.hasEnrolledStudents && !this.hasContractsOrDrafts) {
        return {
          name: 'client-request-new',
        }
      }

      if (!this.hasEnrolledStudents && this.hasContractsOrDrafts) {
        return {
          name: 'client-contracts',
        }
      }

      if (this.isStudentEnrolledAndHasDiagnostics) {
        return {
          name: 'client-contracts',
        }
      }

      return '/'
    },

    classHeaderClient() {
      return {
        's-header-client': this.students.length,
      }
    },

    invoicesLink() {
      return {
        name: 'client-invoices',
      }
    },

    isRendered() {
      return {
        rating: this.rating !== null,
        credit: this.credit !== null,
      }
    },
  },

  watch: {
    noticeText() {
      this.setHeightNoticedMobileMenu()
    },
  },

  methods: {
    selectStudent({ id }) {
      this.$emit('select-student', id)
    },

    getStudentAvatar(photo) {
      return photo?.thumbs?.small ?? ''
    },
  },
}
</script>
