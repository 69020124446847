<template>
  <section :class="tabClass" class="home__tab-pane home__subjects">
    <div v-if="hasSubjects">
      <div
        v-if="educationType.fullTime"
        class="home__subjects-block home__title-full-time"
      >
        <div class="form-row">
          <div class="col-8">
            <span class="t-tag">{{ t('subject') }}</span>
          </div>

          <div class="col-4 text-right">
            <span class="t-tag">{{ t('point') }}</span>
          </div>
        </div>
      </div>

      <div v-if="subjectsRequired.length" class="home__subjects-block">
        <SubjectItemPreview
          v-for="subject in subjectsRequired"
          :key="subject.group.id"
          :subject="subject"
        />
      </div>

      <div v-if="subjectsOptional.length" class="home__subjects-block">
        <SubjectItemPreview
          v-for="subject in subjectsOptional"
          :key="subject.group.id"
          :subject="subject"
        />
      </div>

      <div v-if="subjectsAdditional.length" class="home__subjects-block">
        <SubjectItemPreview
          v-for="subject in subjectsAdditional"
          :key="subject.group.id"
          :subject="subject"
        />
      </div>

      <div v-if="subjectsExtracurricular.length" class="home__subjects-block">
        <div class="t-tag mb-2">{{ t('ctp_showcase') }}</div>

        <SubjectItemPreview
          v-for="subject in subjectsExtracurricular"
          :key="subject.group.id"
          :subject="subject"
        />
      </div>
    </div>

    <div v-else class="b-white__side">
      <div class="text-placeholder text-gray">
        {{ t('no_subjects') }}
      </div>
    </div>
  </section>
</template>

<script>
import SubjectItemPreview from '@frontend/components/student/subjects/SubjectItemPreview.vue'
import { mainTabsStudent } from '@frontend/helpers/mainTabs'
import { mapGetters } from 'vuex'

export default {
  name: 'Subjects',

  components: {
    SubjectItemPreview,
  },

  props: {
    currentTab: {
      type: String,
      default: '',
    },

    subjectsRequired: {
      type: Array,
      default: () => [],
    },

    subjectsOptional: {
      type: Array,
      default: () => [],
    },

    subjectsAdditional: {
      type: Array,
      default: () => [],
    },

    subjectsExtracurricular: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters('student/profile', ['educationType', 'isSchoolEducont']),

    tabClass() {
      return {
        active: this.currentTab === mainTabsStudent.subjects.id,
        'home__subjects--wide':
          this.educationType.fullTime || this.isSchoolEducont,
      }
    },

    hasSubjects() {
      return (
        this.subjectsRequired.length ||
        this.subjectsOptional.length ||
        this.subjectsAdditional.length ||
        this.subjectsExtracurricular.length
      )
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.main.subjects.${name}`)
    },
  },
}
</script>

<style lang="scss">
@import '~@frontend/scss/variables';

.home {
  &__subjects {
    background-color: $white;
    box-shadow: 0 2px 24px rgba(51, 47, 62, 0.08);

    @media (min-width: $lg) {
      width: 200px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    &--wide {
      @media (min-width: $lg) {
        width: 280px;
      }
    }
  }

  &__subjects-block {
    padding: 16px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: 0;
    }

    @media (min-width: $lg) {
      padding: 32px;
    }
  }

  &__title-full-time {
    padding-bottom: 0;
    margin-bottom: -16px;
    border: 0;
  }
}
</style>
