import Vue from 'vue'

/**
 * @param {string} message
 * @param {'success'|'error'|'info'} type
 * @param {number} duration
 */
export const showToast = (message, type = 'success', duration = 3000) => {
  Vue.toasted.show(message, {
    duration,
    type,
  })
}

/**
 * @param {string} message
 * @param {'success'|'error'|'info'} type
 */
export const showToastPersistent = (message, type) => {
  Vue.toasted.show(message, {
    type,
    keepOnHover: true,
    closeOnSwipe: false,
    duration: Infinity,
    action: {
      // TODO: i18n
      text: 'Закрыть',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      },
    },
  })
}
