var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "s-footer" }, [
    _vm.isPreview
      ? _c("ul", { staticClass: "list-unstyled s-footer__nav" }, [
          _c("li", [
            _c("span", { staticClass: "link-dark" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.t("school_website")) + "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", { staticClass: "link-dark" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.t("privacy_policy")) + "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("span", { staticClass: "link-dark" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.t("information_security_memo")) +
                  "\n      "
              ),
            ]),
          ]),
        ])
      : _c("ul", { staticClass: "list-unstyled s-footer__nav" }, [
          _vm.schoolSite
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "link-dark",
                    attrs: {
                      href: _vm.schoolSite,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("school_website")) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.schoolPolicyFileUrl
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "link-dark",
                    attrs: {
                      href: _vm.schoolPolicyFileUrl,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.t("privacy_policy")) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                staticClass: "link-dark",
                attrs: {
                  href: _vm.docs.informationSecurityMemo,
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.t("information_security_memo")) +
                    "\n      "
                ),
              ]
            ),
          ]),
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "text-secondary" }, [
      _c("div", { staticClass: "mb-2" }, [
        _vm._v("© " + _vm._s(_vm.t("intelligent_technologies"))),
      ]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.t("id")) + " " + _vm._s(_vm.userId))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }