var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { staticClass: "s-aside", class: { narrow: _vm.isHidden } },
    [
      _vm.hasLinks
        ? _c(
            "ElButton",
            { staticClass: "s-aside__toggle", on: { click: _vm.toggle } },
            [
              _c("img", {
                attrs: { src: _vm.icons.arrowLeft, alt: "arrow-left" },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "nav",
        { staticClass: "s-aside__menu" },
        [
          _vm._l(_vm.links, function (link) {
            return [
              link.externalUrl
                ? _c(
                    "a",
                    {
                      key: link.label,
                      staticClass: "s-aside__link",
                      class: link.class,
                      attrs: {
                        target: link.isExternal ? "_blank" : false,
                        href: link.externalUrl,
                        rel: link.isExternal ? "noopener noreferrer" : false,
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t(link.labelPath)))]),
                      _vm._v(" "),
                      _vm.hasNotifications(link)
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(link.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "router-link",
                    {
                      key: link.label,
                      staticClass: "s-aside__link",
                      class: link.class,
                      attrs: {
                        target: link.isExternal ? "_blank" : false,
                        to: link.link,
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.$t(link.labelPath)))]),
                      _vm._v(" "),
                      _vm.hasNotifications(link)
                        ? _c("i", { staticClass: "notif" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(link.notifications) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }