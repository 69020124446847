import ApiSubjectActive from 'ApiRest/Api/Account/Student/Subject/Active'
import { ApiClassBookUrl } from 'ApiRest/Api/Account/Student/Subject/ClassBookUrl'
import ApiSubjectCtps from 'ApiRest/Api/Account/Student/Subject/Ctps'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  async fetchSubjects({ commit }) {
    const { data } = await ApiSubjectActive.get()

    commit('setSubjects', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {number} payload.subjectId
   * @param {number} payload.groupId
   * @returns {Promise<?>}
   */
  async fetchSubjectDetail({ commit }, { subjectId, groupId }) {
    const { data } = await ApiSubjectCtps.get(subjectId, groupId)

    commit('setSubjectDetail', data)
  },

  async fetchClassBookUrl({ commit }, { subjectId, groupId }) {
    const { data } = await ApiClassBookUrl.get(subjectId, groupId)

    commit('setClassBookUrl', data)
  },

  resetState({ commit }) {
    commit('resetState')
  },
}
