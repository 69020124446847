/**
 * @returns {Object}
 */
export default () => ({
  links: [
    {
      link: {
        name: 'client-showcase',
      },
      label: 'Академия',
      // TODO: i18n
      labelPath: 'Академия',
      class: 'm-showcase',
      notifications: 0,
    },
    {
      link: {
        name: 'client-showcase-subjects',
      },
      label: 'Мои курсы',
      // TODO: i18n
      labelPath: 'Мои курсы',
      class: 'm-subjects',
      notifications: 0,
    },
    {
      link: {
        name: 'client-showcase-online-lessons',
      },
      label: 'Расписание',
      // TODO: i18n
      labelPath: 'Расписание',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'client-showcase-invoices',
      },
      label: 'Счета',
      // TODO: i18n
      labelPath: 'Счета',
      class: 'm-bills',
      notifications: 0,
    },
    {
      link: {
        name: 'client-notifications',
      },
      label: 'Уведомления',
      // TODO: i18n
      labelPath: 'Уведомления',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'client-support',
      },
      label: 'Поддержка',
      // TODO: i18n
      labelPath: 'Поддержка',
      class: 'm-help',
      notifications: 0,
    },
  ],
})
