import authHelper from '@frontend/helpers/auth'
import { scrollBehavior } from '@frontend/helpers/scrollBehavior'
import NotFound from '@frontend/pages/NotFound.vue'
import { authRoutes } from '@frontend/routes/auth'
import { clientRoutes } from '@frontend/routes/client'
import { previewRoutes } from '@frontend/routes/preview'
import { studentRoutes } from '@frontend/routes/student'
import UserStore from '@frontend/UserStore'
import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiIsAuthorized } from 'ApiRest/Api/Auth/IsAuthorized'
import { ApiEducontAuth } from 'ApiRest/Api/Educont/Auth'
import { ApiUserRedirect } from 'ApiRest/Api/UserRedirect'
import { authUrl } from 'Helpers/const/authUrl'
import { isMobileDomain } from 'Helpers/domain'
import { promiseTimeout } from 'Helpers/promiseTimeout'
import { redirectToAdminArea, redirectToAuth } from 'Helpers/redirectHelper'
import { showToast } from 'Helpers/toast'
import { trackPage } from 'Helpers/trackPage'
import Router from 'vue-router'

const isRoot = {
  root: true,
}

export default new (class UserRouter {
  constructor() {
    this.router = null
  }

  init() {
    this.router = new Router({
      mode: 'history',

      routes: [
        ...authRoutes,
        ...clientRoutes,
        ...studentRoutes,
        ...previewRoutes,

        {
          path: '*',
          name: 'not-found',
          component: NotFound,
        },
      ],

      scrollBehavior,
    })

    /**
     * Открыть раздел клиента
     *
     * @param {Function} next
     * @returns {Function}
     */
    const displayClientSection = (next) =>
      next({
        name: 'client-main',
      })

    /**
     * Открыть раздел ученика
     *
     * @param {Function} next
     * @returns {Function}
     */
    const displayStudentSection = (next) =>
      next({
        name: 'student-main',
      })

    const isClient = () => UserStore.store.getters['common/user/isClient']
    const isStudent = () => UserStore.store.getters['common/user/isStudent']
    const isManager = () => UserStore.store.getters['common/user/isManager']
    const isDemoUser = () => UserStore.store.getters['common/user/isDemoUser']

    const reloadPage = () => window.location.reload()

    const checkLoggedInUser = () => {
      const userId = UserStore.store.state.common.user.id

      if (!userId) {
        return false
      }

      if (
        isClient() &&
        UserStore.store.state.client.profile.profile.id !== userId
      ) {
        reloadPage()
      }

      if (
        isStudent() &&
        UserStore.store.state.student.profile.profile.id !== userId
      ) {
        reloadPage()
      }

      return true
    }

    const setTargetUrl = (to) => {
      if (
        to.name === 'login' ||
        to.name === 'logout' ||
        to.name === 'not-found'
      ) {
        return window.location.origin
      }

      return window.location.href
    }

    const processBeforeEach = async (to, from, next) => {
      const targetUrl = setTargetUrl(to)

      if (to.query.smartcode) {
        try {
          const params = {
            smartcode: to.query.smartcode,
          }

          const { status } = await ApiEducontAuth.get(params)

          if (status === HttpStatusCodes.Accepted) {
            return next({
              name: 'educont-registration',
              params,
            })
          }

          if (to.name === 'educont-invite') {
            return displayStudentSection(next)
          }
        } catch (error) {
          redirectToAuth(authUrl.login, targetUrl)

          return next(false)
        }
      }

      // Если переход на страницу login/logout произошел через роутинг (router-link, router.push()),
      // то делать редирект на authUrl

      if (to.name === 'login') {
        redirectToAuth(authUrl.login, targetUrl)

        return next(false)
      }

      if (to.name === 'logout') {
        redirectToAuth(authUrl.logout, targetUrl)

        return next(false)
      }

      if (to.name === 'password-reset') {
        redirectToAuth(authUrl.recover)

        return next(false)
      }

      const { data } = await ApiIsAuthorized.post()

      if (data === null) {
        UserStore.store.commit('common/user/resetState', null, isRoot)

        // Если страница регистрации, пропустить
        if (authHelper.auth.isAuthPage(to.name)) {
          return next()
        }

        redirectToAuth(authUrl.login, targetUrl)

        return next(false)
      }

      UserStore.store.commit(
        'common/user/setUserValue',
        {
          key: 'id',
          value: data.id,
        },
        isRoot,
      )

      UserStore.store.commit(
        'common/user/setUserValue',
        {
          key: 'role',
          value: data.baseRole,
        },
        isRoot,
      )

      if (isManager()) {
        if (isMobileDomain) {
          showToast('Мобильный поддомен недоступен для менеджеров', 'error')

          await promiseTimeout()

          redirectToAuth(authUrl.logout)

          return next(false)
        }

        // Если менеджер посещает роут "предпросмотра", то пропустить
        if (authHelper.auth.isPreview(to.name)) {
          return next()
        }

        if (to.path === '/' && to.query.chat) {
          return redirectToAdminArea(`messages?chat=${to.query.chat}`)
        }

        return redirectToAdminArea()
      }

      if (
        from === Router.START_LOCATION ||
        authHelper.auth.isAuthPage(to.name)
      ) {
        if (isClient()) {
          await UserStore.store.dispatch(
            'client/profile/fetchProfile',
            null,
            isRoot,
          )
        }

        if (isStudent()) {
          await UserStore.store.dispatch(
            'student/profile/fetchProfile',
            null,
            isRoot,
          )

          if (!isMobileDomain) {
            const { data: urlRedirect } = await ApiUserRedirect.get()

            const schoolName =
              UserStore.store.state.student.profile.profile.school.name

            if (urlRedirect) {
              showToast(
                `Вы вошли в систему под учеником школы "${schoolName}" и будете перенаправлены на её платформу`,
              )

              await promiseTimeout()

              window.location.href = urlRedirect
            }
          }
        }
      }

      checkLoggedInUser()

      if (to.path === '/' && to.query.chat) {
        if (isClient()) {
          return next({
            name: 'client-messages',
            query: to.query,
          })
        }

        if (isStudent()) {
          return next({
            name: 'student-messages',
            query: to.query,
          })
        }
      }

      if (to.path !== '/') {
        if (
          isDemoUser() &&
          authHelper.auth.isDemoUserAccessForbidden(to.name)
        ) {
          return next(false)
        }

        return next()
      }

      if (isClient()) {
        return displayClientSection(next)
      }

      if (isStudent()) {
        return displayStudentSection(next)
      }

      redirectToAuth(authUrl.login, targetUrl)

      return next(false)
    }

    const processAfterEach = async (to, from) => {
      if (isStudent()) {
        await trackPage(to, from)
      }
    }

    this.router.beforeEach(processBeforeEach)
    this.router.afterEach(processAfterEach)

    return this.router
  }
})()
