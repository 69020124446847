export default {
  fullNameWithShortLastName: (state) => {
    if (!state.profile.firstName) {
      return ''
    }

    const words = [state.profile.firstName]

    if (state.profile.lastName) {
      const shortLastName = `${state.profile.firstName[0].toUpperCase()}.`

      words.push(shortLastName)
    }

    return words.join(' ')
  },

  fullName: (state) =>
    [state.profile.lastName, state.profile.firstName, state.profile.middleName]
      .filter(Boolean)
      .join(' '),

  nameAbbreviation: (state) =>
    [state.profile.firstName, state.profile.lastName]
      .filter(Boolean)
      .map((i) => i[0])
      .join('')
      .toUpperCase(),

  isSchoolPlatformDemoAllowed: (state) =>
    Boolean(state.schoolPlatform.isDemoAllowed) ||
    Boolean(state.schoolPlatform.isDemoAllowedFe),
}
