import createState from '@frontend/store/showcase/common/cart/state'
import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

const sortAddFiledsProducts = (products = []) => {
  products.sort((a, b) => a.acquiringId - b.acquiringId)

  return products.map((product, idx) => {
    const isVariousAcquiring =
      products[idx + 1]?.acquiringId &&
      products[idx + 1].acquiringId !== product.acquiringId

    if (isVariousAcquiring) {
      product.isAcquiring = true
    }

    return {
      ...product,
      isChecked: true,
    }
  })
}

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProductsCount(state, productsCount) {
    state.productsCount = productsCount
  },

  setProducts(state, products) {
    state.products = sortAddFiledsProducts(products)
  },

  checkProduct(state, product) {
    product.isChecked = true
  },

  uncheckProduct(state, product) {
    product.isChecked = false
  },

  checkAllProducts(state) {
    state.products.forEach((product) => {
      Vue.set(product, 'isChecked', true)
    })
  },

  uncheckAllProducts(state) {
    state.products.forEach((product) => {
      Vue.set(product, 'isChecked', false)
    })
  },

  setPurchase(state, payload) {
    state.purchase = payload
  },
}
