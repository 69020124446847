import ApiBase from 'ApiRest/ApiBase'

export const ApiIncomeDiagnosticsExtramuralHasAgreement =
  new (class extends ApiBase {
    /**
     * Проверка, есть ли доп.соглашение на заочную диагностику
     *
     * @param {number} applicationId
     * @returns {Promise}
     */
    get(applicationId) {
      const url = `/diagnostics/applications/${applicationId}/has-extramural-agreement`

      return this._GET(url)
    }
  })()
