import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiShowcaseOnlineLessonCancel } from 'ApiRest/Api/Showcase/OnlineLesson/Cancel'
import { ApiShowcaseOnlineLessonIsRunning } from 'ApiRest/Api/Showcase/OnlineLesson/IsRunning'
import { ApiShowcaseOnlineLessonJoin } from 'ApiRest/Api/Showcase/OnlineLesson/Join'
import { ApiShowcaseOnlineLessonList } from 'ApiRest/Api/Showcase/OnlineLesson/List'
import { ApiShowcaseOnlineLesson } from 'ApiRest/Api/Showcase/OnlineLesson/OnlineLesson'
import { ApiShowcaseOnlineLessonSignUp } from 'ApiRest/Api/Showcase/OnlineLesson/SignUp'

export default {
  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   */
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {Object} payload
   * @param {string} payload.periodStart
   * @param {string|null} payload.periodEnd
   */
  async fetchOnlineLessons({ commit }, { periodStart, periodEnd = null }) {
    const parameters = {
      periodStart,
      periodEnd: periodEnd ?? periodStart,
      onlyMineLessons: true,
    }

    const { data, request } = await ApiShowcaseOnlineLessonList.get(parameters)

    if (request.status === HttpStatusCodes.NoContent) {
      commit('hideCalendar')
      commit('setOnlineLessons', [])
    } else {
      commit('setOnlineLessons', data)
    }
  },

  /**
   * @param {Object|undefined} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {number} lessonId
   * @returns {Object}
   */
  async fetchTheOnlineLesson({ state, commit }, lessonId) {
    if (state.detailLessons[lessonId]) {
      return state.detailLessons[lessonId]
    }

    const { data } = await ApiShowcaseOnlineLesson.get(lessonId, {
      expand: 'conferenceType',
    })

    commit('setTheOnlineLesson', data)

    return data
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async signUpTheOnlineLesson({ commit }, lessonId) {
    await ApiShowcaseOnlineLessonSignUp.put(lessonId)

    commit('setSignUpTheOnlineLesson', lessonId)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async signCancelTheOnlineLesson({ commit }, lessonId) {
    await ApiShowcaseOnlineLessonCancel.put(lessonId)

    commit('setSignCancelTheOnlineLesson', lessonId)
  },

  /**
   * @param {Object|undefined} context
   * @param {Function} context.commit
   * @param {number} lessonId
   */
  async fetchBigBlueButtonLessonIsRunning({ commit }, lessonId) {
    const { data } = await ApiShowcaseOnlineLessonIsRunning.get(lessonId)

    commit('setTheOnlineLessonIsRunning', {
      lessonId,
      value: data,
    })
  },

  async fetchBigBlueButtonLessonUrl({ commit }, lessonId) {
    const data = ApiShowcaseOnlineLessonJoin.get(lessonId)

    commit('setTheOnlineLessonUrl', data)
  },

  async fetchBigBlueButtonLessonTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setTheOnlineLessonTimestamp', data * 1000)
  },
}
