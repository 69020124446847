import ApiBase from 'ApiRest/ApiBase'

export const ApiIncomeDiagnosticsRetakeAgreement = new (class extends ApiBase {
  /**
   * Скачивания доп.соглашения на пересдачу
   *
   * @param {number} applicationId
   * @param {number} retakeId
   * @returns {Promise}
   */
  get(applicationId, retakeId) {
    const config = {
      responseType: 'blob',
    }
    const url = `/diagnostics/applications/${applicationId}/retake-agreement-pdf/${retakeId}`

    return this._GET(url, config)
  }
})()
