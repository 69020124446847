import ApiBase from 'ApiRest/ApiBase'
import UrlHelper from 'Helpers/UrlHelper'

// TODO: разбить файл на несколько частей

/**
 * Возвращает списки (например для DropDownList)
 */
export default new (class Option extends ApiBase {
  /**
   * Типы учебного года
   *
   * @returns {Promise<Object>}
   */
  getAcademicYearTypes() {
    return this._GET(`/option/academic-year-types`)
  }

  /**
   * Академические годы
   *
   * @returns {Promise<Object>}
   */
  getAcademicYearPreset() {
    return this._GET(`/option/academic-year-preset`)
  }

  /**
   * Список городов/регионов в которых есть школы
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getCities(parameters = {}) {
    return this._GET(`/option/cities?${UrlHelper.param(parameters)}`)
  }

  /**
   * Гражданства
   *
   * @returns {Promise<Object>}
   */
  getCitizenships() {
    return this._GET(`/option/citizenships`)
  }

  /**
   * Статусы контента
   *
   * @returns {Promise<Object>}
   */
  getContentStatuses() {
    return this._GET(`/option/content-statuses`)
  }

  /**
   * Статусы обратной связи
   *
   * @returns {Promise<Object>}
   */
  getFeedbackStatuses() {
    return this._GET(`/option/feedback-statuses`)
  }

  /**
   * Страны
   *
   * @returns {Promise<Object>}
   */
  getCountries() {
    return this._GET(`/option/countries`)
  }

  /**
   * Тип подсчёта итоговой оценки за попытки
   *
   * @returns {Promise<Object>}
   */
  getCtpTestAttemptsMarkType() {
    return this._GET(`/option/ctp-test-attempts-mark-type`)
  }

  /**
   * КТП-Тест. Тип теста
   *
   * @returns {Promise<Object>}
   */
  getCtpTestTypes() {
    return this._GET(`/option/ctp-test-types`)
  }

  /**
   * КТП-Тест. Получить тип оценки
   * TODO: метод совпадает с api/rest/Api/Certification/Option/MarkingType.get()
   *
   * @returns {Promise<Object>}
   */
  getCtpTestMarkingType() {
    return this._GET(`/option/test-marking-types`)
  }

  /**
   * КТП-Тест. Показывать ли правильные ответы (тип отображения)
   *
   * @returns {Promise<Object>}
   */
  getCtpTestShowAnswerTypes() {
    return this._GET(`/option/ctp-test-show-answer-types`)
  }

  /**
   * КТП-Тест. Когда показывать ответы в тестах
   *
   * @returns {Promise<Object>}
   */
  getCtpTestWhenShowAnswerTypes() {
    return this._GET(`/option/ctp-test-when-show-answer-types`)
  }

  /**
   * Демо-Тест
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getDemoTests(parameters = {}) {
    return this._GET(`/option/demo-tests?${UrlHelper.param(parameters)}`)
  }

  /**
   * Статусы запросов на зачисление
   *
   * @returns {Promise<Object>}
   */
  getEnrollmentRequestStatuses() {
    return this._GET(`/option/enrollment-request-statuses`)
  }

  /**
   * Статусы договоров на зачисление
   *
   * @returns {Promise<Object>}
   */
  getEnrollmentContractStatuses() {
    return this._GET(`/option/enrollment-contract-statuses`)
  }

  /**
   * Типы договоров на зачисление
   *
   * @returns {Promise<Object>}
   */
  getEnrollmentContractTypes() {
    return this._GET(`/option/enrollment-contract-types`)
  }

  /**
   * Способы подачи заявления
   *
   * @returns {Promise<Object>}
   */
  getEnrollmentContractSubmissionTypes() {
    return this._GET(`/option/enrollment-contract-submission-types`)
  }

  /**
   * Категории семьи
   *
   * @returns {Promise<Object>}
   */
  getFamilyCategories() {
    return this._GET(`/option/family-categories`)
  }

  /**
   * Оценки
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getGrades(parameters = {}) {
    return this._GET(`/option/grades?${UrlHelper.param(parameters)}`)
  }

  /**
   * Типы обучения
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getEducationTypes(parameters = {}) {
    return this._GET(`/option/education-types?${UrlHelper.param(parameters)}`)
  }

  /**
   * Срок освоения
   *
   * @returns {Promise<Object>}
   */
  getDevelopmentPeriods() {
    return this._GET(`/option/development-periods`)
  }

  /**
   * Вид образовательной деятельности
   *
   * @returns {Promise<Object>}
   */
  getEducationActivities() {
    return this._GET(`/option/education-activities`)
  }

  /**
   * @returns {Promise<Object>}
   */
  getHealthRestrictions() {
    return this._GET(`/option/health-restrictions`)
  }

  /**
   * Степени родства
   *
   * @returns {Promise<Object>}
   */
  getKinshipDegrees() {
    return this._GET(`/option/kinship-degrees`)
  }

  /**
   * Менеджеры
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getManagers(parameters = {}) {
    return this._GET(`/option/managers?${UrlHelper.param(parameters)}`)
  }

  /**
   * Модерация: Статусы Контента
   *
   * @returns {Promise<Object>}
   */
  getModerationContentStatuses() {
    return this._GET(`/option/moderation-content-statuses`)
  }

  /**
   * Модерация: Статусы Вопроса
   *
   * @returns {Promise<Object>}
   */
  getModerationQuestionStatuses() {
    return this._GET(`/option/moderation-question-statuses`)
  }

  /**
   * Типы документов, подтверждающие московскую регистрацию
   *
   * @returns {Promise<Object>}
   */
  getMoscowRegistrationConfirmDocTypes() {
    return this._GET(`/option/moscow-registration-confirm-doc-types`)
  }

  /**
   * Типы регистраций в Москве
   *
   * @returns {Promise<Object>}
   */
  getMoscowRegistrationTypes() {
    return this._GET(`/option/moscow-registration-types`)
  }

  /**
   * Типы вопросов
   *
   * @returns {Promise<Object>}
   */
  getQuestionTypes() {
    return this._GET(`/option/question-types`)
  }

  /**
   * Типы проверки вопросов
   *
   * @returns {Promise<Object>}
   */
  getQuestionCheckTypes() {
    return this._GET(`/option/question-check-types`)
  }

  /**
   * Статусы вопросов
   *
   * @returns {Promise<Object>}
   */
  getQuestionStatuses() {
    return this._GET(`/option/question-statuses`)
  }

  /**
   * Услуги
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getInvoiceServices(parameters = {}) {
    return this._GET(`/option/invoice-services?${UrlHelper.param(parameters)}`)
  }

  /**
   * Счета статусы
   *
   * @returns {Promise<Object>}
   */
  getInvoiceStatuses() {
    return this._GET(`/option/invoice-statuses`)
  }

  /**
   * Школы
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getSchools(parameters = {}) {
    return this._GET(`/option/schools?${UrlHelper.param(parameters)}`)
  }

  /**
   * Варианты биологического пола
   *
   * @returns {Promise<Object>}
   */
  getSexes() {
    return this._GET(`/option/sexes`)
  }

  /**
   * Предметы
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getSubjects(parameters = {}) {
    return this._GET(`/option/subjects?${UrlHelper.param(parameters)}`)
  }

  /**
   * Типы предметов
   *
   * @returns {Promise<Object>}
   */
  getSubjectTypes() {
    return this._GET(`/option/subject-types`)
  }

  /**
   * Список статусов тестов
   *
   * @returns {Promise<Object>}
   */
  getTestingStatuses() {
    return this._GET(`/option/testing-statuses`)
  }

  /**
   * Список статусов разрешения
   *
   * @returns {Promise<Object>}
   */
  getRenegotiationAvailableStatuses() {
    return this._GET(`/option/renegotiation-available-status`)
  }

  /**
   * Статусы аккаунтов учеников
   *
   * @returns {Promise<Object>}
   */
  getStudentAccountStatuses() {
    return this._GET(`/option/student-account-statuses`)
  }

  /**
   * Статусы заявок на зачисление учеников
   *
   * @returns {Promise<Object>}
   */
  getStudentEnrollmentStatuses() {
    return this._GET(`/option/student-enrollment-statuses`)
  }

  /**
   * Тесты
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getTests(parameters = {}) {
    return this._GET(`/option/tests?${UrlHelper.param(parameters)}`)
  }

  /**
   * Тесты
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getMiniTests(parameters = {}) {
    return this._GET(`/option/mini-tests?${UrlHelper.param(parameters)}`)
  }

  /**
   * Типы тестов
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getTestTypes(parameters = {}) {
    return this._GET(`/option/test-types?${UrlHelper.param(parameters)}`)
  }

  /**
   * Тест тип проверки
   *
   * @returns {Promise<Object>}
   */
  getTestCheckTypes() {
    return this._GET(`/option/test-check-types`)
  }

  /**
   * Является ли тест демо тестом
   *
   * @returns {Promise<Object>}
   */
  getTestIsDemo() {
    return this._GET(`/option/test-is-demo`)
  }

  /**
   * Список потоков
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getStreams(parameters = {}) {
    return this._GET(`/option/streams?${UrlHelper.param(parameters)}`)
  }

  /**
   * Упрощенный список потоков (только id, name)
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  getStreamsLite(parameters = {}) {
    return this._GET(`/option/streams-lite?${UrlHelper.param(parameters)}`)
  }

  /**
   * Список КТП
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getCtps(parameters = {}) {
    return this._GET(`/option/ctps?${UrlHelper.param(parameters)}`)
  }

  /**
   * Упрощенный список (id, name) КТП
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getCtpsLite(parameters = {}) {
    return this._GET(`/option/ctps-lite?${UrlHelper.param(parameters)}`)
  }

  /**
   * Список УМК
   *
   * @returns {Promise<Object>}
   */
  getTrainingPrograms() {
    return this._GET('/option/training-programs')
  }

  /**
   * Список ГРУПП+КТП
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getCtpsGroups(parameters = {}) {
    return this._GET(`/option/ctps-groups?${UrlHelper.param(parameters)}`)
  }

  /**
   * Список типов материалов
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getMaterialTypes(parameters = {}) {
    const url = `/option/material-types?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }

  /**
   * Список аттестаций
   *
   * @returns {Promise<Object>}
   */
  getCertifications() {
    return this._GET('/option/certs')
  }

  /**
   * Список аттестаций СО
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  getFamilyEducationCertifications(parameters = {}) {
    return this._GET(`/option/fe-certs?${UrlHelper.param(parameters)}`)
  }

  /**
   * Список типов синхронизаций с внешним сервисом (CRM / ERP)
   *
   * @returns {Promise<Object>}
   */
  getExternalAppSyncTypes() {
    return this._GET('/option/external-app-sync-types')
  }

  /**
   * Список статусов вики
   *
   * @returns {Promise<Object>}
   */
  getWikiGroupStatuses() {
    return this._GET('/option/wiki-group-statuses')
  }

  /**
   * Список типов скидки
   *
   * @returns {Promise<Object>}
   */
  getDiscountTypes() {
    return this._GET('/option/discount-types')
  }

  /**
   * Список статусов аттестаций СО
   *
   * @returns {Promise<Object>}
   */
  getFamilyEducationCertStatuses() {
    return this._GET('/option/family-education-cert-statuses')
  }

  /**
   * Список способов подачи документов на аттестацию СО
   *
   * @returns {Promise<Object>}
   */
  getFamilyEducationCertRequestSubmissionType() {
    return this._GET('/option/family-education-cert-request-submission-type')
  }

  /**
   * Список периодов аттестаций СО
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getFamilyEducationCertSchedule(parameters = {}) {
    return this._GET(
      `/option/family-education-cert-schedule?${UrlHelper.param(parameters)}`,
    )
  }

  /**
   * Список статусов заявок на аттестацию СО
   *
   * @returns {Promise<Object>}
   */
  getFamilyEducationCertRequestStatus() {
    return this._GET('/option/family-education-cert-request-status')
  }

  /**
   * Список степеней родства у заявок на аттестацию СО
   *
   * @returns {Promise<Object>}
   */
  getLegalRepresentativeTypes() {
    return this._GET('/option/legal-representative-types')
  }

  /**
   * Список типов КТП
   *
   * @returns {Promise}
   */
  getCtpTypes() {
    return this._GET('/option/ctp-type')
  }

  /**
   * Список проверяющих учителей
   *
   * @param {number} schoolId - id школы
   * @param {Object} params
   * @returns {Promise<Object>}
   */
  getCtpCheckers(schoolId, params = {}) {
    return this._GET(
      `/option/schools/${schoolId}/ctp-checkers?${UrlHelper.param(params)}`,
    )
  }

  /**
   * Список ролей в системе
   *
   * @returns {Promise}
   */
  getUserRoles() {
    return this._GET('/option/user-roles')
  }

  /**
   * Список партнёров
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  getPartners(parameters = {}) {
    return this._GET(`/option/partners-get-list?${UrlHelper.param(parameters)}`)
  }

  /**
   * Список виджетов (дашбордов)
   *
   * @returns {Promise}
   */
  getWidgetTypes() {
    return this._GET('/widget/option/types')
  }

  /**
   * Список тэгов
   *
   * @param {Object} parameters
   * @returns {Promise}
   */
  getEducontTags(parameters = {}) {
    return this._GET(`/option/educont-tag?${UrlHelper.param(parameters)}`)
  }

  /**
   * Список эквайрингов
   *
   * @returns {Promise}
   */
  getAcquirings() {
    return this._GET('/option/acquirings')
  }

  /**
   * Список методов оплаты
   *
   * @returns {Promise}
   */
  getPaymentMethods() {
    return this._GET(`/option/service/payment-methods`)
  }

  /**
   * Упрощенный список (id, name) Онлайн-уроков
   *
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  getOnlineLessonsLite(parameters = {}) {
    return this._GET(
      `/option/online-lessons-lite?${UrlHelper.param(parameters)}`,
    )
  }

  getLegalEntities(parameters = {}) {
    return this._GET(`/option/school-entities?${UrlHelper.param(parameters)}`)
  }

  /**
   * Список Долей
   *
   * @returns {Promise}
   */
  getDolyameAcquirings() {
    return this._GET('/option/dolyame-acquirings')
  }

  /**
   * @returns {Promise}
   */
  getStudentBlockReasons() {
    return this._GET(`/option/block-reasons`)
  }

  /**
   * @returns {Promise}
   */
  getStudentUnblockReasons() {
    return this._GET(`/option/unblock-reasons`)
  }

  /**
   * Справочник статусов продукта
   *
   * @returns {Promise<Object>}
   */
  getProductStatuses() {
    return this._GET(`/option/product-statuses`)
  }

  /**
   * Справочник ролей
   *
   * @returns {Promise<Object>}
   */
  getPublicRoles() {
    return this._GET('/option/public-roles')
  }

  /**
   * Доступ к учебникам Просвещения
   *
   * @returns {Promise<Object>}
   */
  getEnlightenmentClassBookAccessTypes() {
    return this._GET('/option/enlightenment-class-book-access-types')
  }
})()
