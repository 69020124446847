import { ApiCoursesCart } from 'ApiRest/Api/Courses/Cart'
import { ApiCoursesCartCheckout } from 'ApiRest/Api/Courses/Cart/Checkout'
import { ApiCoursesCartProductsCount } from 'ApiRest/Api/Courses/Cart/ProductsCount'
import Vue from 'vue'

// TODO: Закомментировано, на время отсутствия нового счетчика для курсов
// import { eventBus } from '@/pubsub/bus'

// const globalMessageOnProductRemoved = function ({ product }) {
//  eventBus.$emit('frontend.showcase.product.productRemoved', {
//    product,
//  })
// }

export default {
  async fetchProductsCount({ commit }) {
    const { data } = await ApiCoursesCartProductsCount.get()

    commit('setProductsCount', data)
  },

  async fetchProducts({ commit }) {
    const { data } = await ApiCoursesCart.get()

    commit('setProducts', data)
  },

  async addProduct({ rootState }) {
    const { user, offer } = rootState.courses.common.product

    await ApiCoursesCart.post({
      offer: {
        id: offer.id,
      },
      consumer: {
        id: user.id,
      },
    })
  },

  async removeProduct(context, product) {
    try {
      await ApiCoursesCart.delete({
        offer: {
          id: product.offer.id,
        },
        consumer: {
          id: product.consumer.id,
        },
      })
      // TODO: Закомментировано, на время отсутствия нового счетчика для курсов
      // globalMessageOnProductRemoved({
      //  product,
      // })
    } catch (error) {
      Vue.toasted.show({
        type: 'error',
        duration: 3000,
      })

      throw error
    }
  },

  async checkout({ getters, commit }) {
    const { data } = await ApiCoursesCartCheckout.post(
      getters.checkedProducts.map((product) => ({
        id: product.offer.id,
        consumer: {
          id: product.consumer.id,
        },
      })),
    )

    commit('setPurchase', data)
  },

  async addProductByLink(context, offerId) {
    await ApiCoursesCart.post({
      offer: {
        id: offerId,
      },
    })
  },
}
