import createState from '@frontend/store/showcase/common/product/state'
import { RESET_STATE } from 'Helpers/ResetState'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setProductId(state, payload) {
    state.productId = Number(payload)
  },

  setPurchaseId(state, payload) {
    state.purchaseId = Number(payload)
  },

  setPurchaseItemId(state, payload) {
    state.purchaseItemId = Number(payload)
  },

  setProduct(state, payload) {
    state.product = payload
  },

  setUsers(state, payload) {
    state.users = payload
  },

  setUser(state, payload) {
    state.user = payload
  },

  setOffers(state, payload) {
    state.offers = payload
  },

  unsetOffers(state) {
    state.offers = []
  },

  setOffer(state, payload) {
    state.offer = payload
  },

  unsetOffer(state) {
    state.offer = null
  },

  setServerTimestamp(state, payload) {
    state.serverTimestamp = payload
  },
}
