import ApiSchool from 'ApiRest/Api/Auth/Options/School'
import ApiRegistration from 'ApiRest/Api/Auth/Registration'
import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import ApiConfirmEmail from 'ApiRest/Api/User/ConfirmEmail'
import { mainSchool } from 'Helpers/const/AppData'
import { getSearchParam } from 'Helpers/searchParams'

export default {
  register({ state }) {
    const payload = {
      ...state.registerData,

      registrationDomain: window.domains.main,
      fromShowcase: false,
    }

    const targetUrl = getSearchParam('targetUrl')

    if (targetUrl !== null) {
      payload.targetUrl = targetUrl
    }

    return ApiRegistration.post(payload)
  },

  async fetchSchoolPlatform({ commit }) {
    const domain = window.domains.main
    let { id } = mainSchool

    const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

    if (subdomainsList.length > 0) {
      const school = subdomainsList.find((subdomain) => subdomain.self)

      if (school !== undefined) {
        id = school.schoolId
      }
    }

    const { data } = await ApiSchool.get(id)

    commit('setSchoolPlatform', data)
  },

  async confirmEmail(context, code) {
    return ApiConfirmEmail.patch({
      code,
    })
  },
}
