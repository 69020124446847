import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import ApiFamilyCertifications from 'ApiRest/Api/Account/Student/Test/FamilyCertification/List'
import ApiCalendarItems from 'ApiRest/Api/CalendarItems'
import { getDate } from 'Helpers/dateFormatHelper'

export default {
  /**
   * @param {Function} commit
   * @param {Object} payload
   * @param {string} payload.start
   * @param {string|null} payload.end
   * @returns {Promise}
   */
  async fetchItems({ commit }, { start, end = null }) {
    let certificationsData = []

    await ApiFamilyCertifications.get().then(({ data }) => {
      certificationsData = data
    })

    certificationsData = certificationsData.map((certification) => {
      const { scheduleDateBegin, scheduleDateEnd, priorityDateEnd } =
        certification.requestPeriod
      const dateStart = scheduleDateBegin
      let dateEnd = ''

      if (new Date(scheduleDateEnd) > new Date(priorityDateEnd)) {
        dateEnd = scheduleDateEnd
      } else {
        dateEnd = priorityDateEnd
      }

      return {
        name: certification.certificationName,
        type: {
          id: 4,
          name: 'Аттестация',
        },
        dateStart: getDate(dateStart, 'yyyy-MM-dd'),
        dateEnd: getDate(dateEnd, 'yyyy-MM-dd'),
        timeStart: getDate(dateStart, 'HH:mm'),
        timeEnd: getDate(dateEnd, 'HH:mm'),
        id: certification.id,
      }
    })

    const calendarItems = await ApiCalendarItems.get({
      start,
      end,
      onlyMineLessons: true,
    })

    if (calendarItems?.request.status === HttpStatusCodes.NoContent) {
      calendarItems.data = []
    }

    commit('setItems', [...calendarItems.data, ...certificationsData])
  },
}
