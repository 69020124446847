import { RESET_STATE } from 'Helpers/ResetState'
import Vue from 'vue'

import createState from './state'

/**
 * @private
 * @param {{}} state
 * @param {number} id
 * @param {boolean} value
 */
const findLesson = (state = {}, id = 0, value = false) => {
  const theLesson = state.lessons.find((i) => Number(i.id) === Number(id))

  if (theLesson) {
    theLesson.isSigned = value
  }

  if (state.detailLessons[id]) {
    state.detailLessons[id].isSigned = value
  }
}

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} lessons
   */
  setOnlineLessons(state, lessons) {
    state.lessons = lessons
  },

  /**
   * @param {Object} state
   * @param {Object} lesson
   */
  setTheOnlineLesson(state, lesson) {
    Vue.set(state.detailLessons, lesson.id, lesson)
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {number} payload.lessonId
   * @param {boolean} payload.value
   */
  setTheOnlineLessonIsRunning(state, { lessonId, value }) {
    Vue.set(state.isRunningLessons, lessonId, value)
  },

  setTheOnlineLessonUrl(state, payload) {
    state.url = payload
  },

  setTheOnlineLessonTimestamp(state, payload) {
    state.timestamp = payload
  },

  /**
   * @param {Object} state
   * @param {number} id
   */
  setSignUpTheOnlineLesson(state, id) {
    findLesson(state, id, true)
  },

  /**
   * @param {Object} state
   * @param {number} id
   */
  setSignCancelTheOnlineLesson(state, id) {
    findLesson(state, id, false)
  },

  showCalendar(state) {
    state.isShownCalendar = true
  },

  hideCalendar(state) {
    state.isShownCalendar = false
  },
}
