export const studentShowcaseRoutes = [
  {
    path: 'showcase',
    component: () =>
      import(
        /* webpackChunkName: "showcase" */
        '@frontend/pages/showcase/common/Showcase.vue'
      ),

    children: [
      {
        path: '',
        name: 'student-showcase',
        component: () =>
          import(
            /* webpackChunkName: "showcase-home" */
            '@frontend/pages/showcase/common/Home.vue'
          ),
      },

      {
        path: 'catalog',
        name: 'student-showcase-catalog',
        component: () =>
          import(
            /* webpackChunkName: "showcase-catalog" */
            '@frontend/pages/showcase/common/Catalog.vue'
          ),
      },

      {
        path: 'products/:productId(\\d+)',
        name: 'student-showcase-product',
        component: () =>
          import(
            /* webpackChunkName: "showcase-product" */
            '@frontend/pages/showcase/common/Product.vue'
          ),
      },

      {
        path: 'collections/:collectionId(\\d+)',
        name: 'student-showcase-collection',
        component: () =>
          import(
            /* webpackChunkName: "showcase-collection" */
            '@frontend/pages/showcase/common/Collection.vue'
          ),
      },

      {
        path: 'cart',
        name: 'student-showcase-cart',
        component: () =>
          import(
            /* webpackChunkName: "showcase-cart" */
            '@frontend/pages/showcase/common/Cart.vue'
          ),
      },

      {
        path: 'purchases',
        name: 'student-showcase-purchases',
        component: () =>
          import(
            /* webpackChunkName: "showcase-purchases" */
            '@frontend/pages/showcase/common/Purchases.vue'
          ),
      },

      {
        path: 'purchases/:purchaseId(\\d+)',
        name: 'student-showcase-the-purchase',
        component: () =>
          import(
            /* webpackChunkName: "student-showcase-purchase" */
            '@frontend/pages/showcase/student/Purchase.vue'
          ),
      },

      {
        path: 'purchases/:purchaseId(\\d+)/items/:purchaseItemId(\\d+)',
        name: 'student-showcase-product-purchased',
        component: () =>
          import(
            /* webpackChunkName: "student-showcase-product-purchased" */
            '@frontend/pages/showcase/common/ProductPurchased.vue'
          ),
        children: [
          {
            path: 'materials',
            name: 'student-showcase-product-purchased-materials',
            component: () =>
              import(
                /* webpackChunkName: "client-showcase-product-purchased-materials" */
                '@frontend/pages/showcase/common/Materials.vue'
              ),
          },
        ],
      },

      {
        path: '/cart/:offerId(\\d+)',
        name: 'student-showcase-product-link',
        component: () =>
          import(
            /* webpackChunkName: "showcase-product-link" */
            '@frontend/pages/showcase/common/ProductLink.vue'
          ),
      },
    ],
  },
]
