import { mainTabsClient } from '@frontend/helpers/mainTabs'

const initialTab = mainTabsClient[Object.keys(mainTabsClient)[0]].id

/**
 * @returns {Object}
 */
export default () => {
  const links = [
    {
      link: {
        name: 'client-contracts',
      },
      label: 'Договоры',
      labelPath: 'nav.contracts',
      class: 'm-contracts',
      notifications: 0,
    },
    {
      link: {
        name: 'client-invoices',
      },
      label: 'Счета',
      labelPath: 'nav.bills',
      class: 'm-bills',
      notifications: 0,
    },
    {
      link: {
        name: 'client-certification',
      },
      label: 'Аттестация',
      labelPath: 'nav.attestation',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-lessons',
      },
      label: 'Уроки',
      labelPath: 'nav.lessons',
      class: 'm-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-statements',
      },
      label: 'Ведомость',
      labelPath: 'nav.statement',
      class: 'm-transcript',
      notifications: 0,
    },
    {
      link: {
        name: 'client-income-diagnostics',
      },
      // TODO: i18n
      label: 'Диагностика',
      labelPath: 'Диагностика',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-news',
      },
      label: 'Новости',
      labelPath: 'nav.news',
      class: 'm-news',
      notifications: 0,
    },
    {
      link: {
        name: 'client-library-enrollment',
      },
      label: 'Запись в библиотеку',
      labelPath: 'nav.library_enrollment',
      class: 'm-library-enrollment',
      notifications: 0,
      isExternal: true,
      externalUrl: 'https://form.xn--80aatfgkndeix6k.xn--p1ai/?id=2477',
    },
    {
      link: {
        name: 'client-schedule-lessons',
      },
      label: 'Расписание',
      labelPath: 'nav.schedule',
      class: 'm-schedule-lessons',
      notifications: 0,
    },
    {
      link: {
        name: 'client-calendar',
      },
      label: 'Календарь',
      labelPath: 'nav.calendar',
      class: 'm-calendar',
      notifications: 0,
    },
    {
      link: {
        name: 'client-goals',
      },
      label: 'Цели',
      labelPath: 'nav.goals',
      class: 'm-goals',
      notifications: 0,
    },
    {
      link: {
        name: 'client-messages',
      },
      label: 'Сообщения',
      labelPath: 'nav.messages',
      class: 'm-messages',
      notifications: 0,
    },
    // {
    //   link: {
    //     name: 'client-notifications',
    //   },
    //   label: 'Уведомления',
    //   labelPath: 'nav.notifications',
    //   class: 'm-notifications',
    //   notifications: 5,
    // },
    {
      link: {
        name: 'client-events',
      },
      label: 'События',
      labelPath: 'nav.events',
      class: 'm-events',
      notifications: 0,
      isExternal: true,
      externalUrl: 'https://events.iblschool.ru/',
    },
    {
      link: {
        name: 'client-showcase',
      },
      label: 'Маркетплейс',
      // TODO: i18n
      labelPath: 'Маркетплейс',
      class: 'm-showcase',
      notifications: 0,
      isExternal: true,
      externalUrl: `${window.domains.protocol}://${window.domains.showcase}/client/showcase`,
    },
    {
      link: {
        name: 'client-courses',
      },
      label: 'Мои курсы',
      // TODO: i18n
      labelPath: 'Мои курсы',
      class: 'm-assessment',
      notifications: 0,
    },
    {
      link: {
        name: 'client-support',
      },
      label: 'Поддержка',
      labelPath: 'nav.support',
      class: 'm-help',
      notifications: 0,
    },
    {
      link: {
        name: 'client-library',
      },
      label: 'Библиотека',
      labelPath: 'nav.library',
      class: 'm-library',
      notifications: 0,
    },
  ]

  return {
    currentTab: initialTab,
    links,
  }
}
