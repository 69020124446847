import {
  fetchTelegramBotLink,
  unlinkTelegramBot,
} from '@frontend/helpers/profile/telegramBot'
import { ApiProfilePhoto } from 'ApiRest/Api/Account/Profile/Photo'
import { ApiEducontGrades } from 'ApiRest/Api/Account/Student/Educont/Grades'
import { ApiAccountStudentEducontStream } from 'ApiRest/Api/Account/Student/Educont/Stream'
import ApiPasswordChange from 'ApiRest/Api/Account/Student/PasswordChange'
import ApiProfile from 'ApiRest/Api/Account/Student/Profile'
import ApiSchool from 'ApiRest/Api/Account/Student/School'
import ApiNotification from 'ApiRest/Api/Notification'
import ApiNotificationEmail from 'ApiRest/Api/Notification/Email'
import ApiNotificationMobileDevice from 'ApiRest/Api/Notification/MobileDevice'
import ApiNotificationTelegram from 'ApiRest/Api/Notification/Telegram'
import ApiUserSettings from 'ApiRest/Api/UserSettings'
import { getCookie } from 'Helpers/cookie'
import { LocaleControllerForSchoolStudent } from 'Helpers/models/LocaleControllerForSchoolStudent'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchProfile({ commit }) {
    const { data } = await ApiProfile.get()

    commit('setProfile', data)

    commit(
      'common/user/setUserValue',
      {
        key: 'isDemo',
        value: data.isDemo,
      },
      {
        root: true,
      },
    )

    commit(
      'common/user/setUserValue',
      {
        key: 'email',
        value: data.email,
      },
      {
        root: true,
      },
    )

    const localeController = new LocaleControllerForSchoolStudent({
      institutionId: data.school.id,
      lang: getCookie('lang') || 'ru',
    })

    await localeController.changeLocale()
  },

  async updateProfile({ state }) {
    await ApiProfile.patch(state.profile)
  },

  async fetchNotifications({ commit }) {
    const { data } = await ApiNotification.get()

    commit('setNotifications', data)
  },

  async fetchTelegramNotifications({ state, commit }) {
    const { data } = await ApiNotificationTelegram.get()

    commit('setNotifications', {
      ...state.notifications,
      telegram: data,
    })
  },

  async sendTelegramChatId({ state, commit }, chatId) {
    const {
      data: { success, payload },
    } = await ApiNotificationTelegram.post(Number(chatId))

    if (payload) {
      commit('setNotifications', {
        ...state.notifications,
        telegram: {
          ...state.notifications.telegram,
          payload,
        },
      })
    }

    return success
  },

  async removeTelegramNotifications() {
    await ApiNotificationTelegram.delete()
  },

  async fetchTelegramBotLink({ commit }) {
    const { isLinked, link } = await fetchTelegramBotLink()

    commit('setEventsTelegramIsLinked', isLinked)
    commit('setEventsTelegramLink', link)
  },

  async unlinkTelegramBot({ commit }) {
    const { link } = await unlinkTelegramBot()

    commit('setEventsTelegramIsLinked', false)
    commit('setEventsTelegramLink', link)
  },

  subscribeEmailNotifications() {
    return ApiNotificationEmail.post()
  },

  unsubscribeEmailNotifications() {
    return ApiNotificationEmail.delete()
  },

  subscribeMobileDeviceNotifications() {
    return ApiNotificationMobileDevice.post()
  },

  unsubscribeMobileDeviceNotifications() {
    return ApiNotificationMobileDevice.delete()
  },

  async fetchSchool({ commit }, payload) {
    const { data } = await ApiSchool.get(payload)

    commit('setSchool', data)
  },

  updateProfilePasswords(context, payload) {
    return ApiPasswordChange.patch(payload)
  },

  async fetchUserSettings({ commit }) {
    const { data } = await ApiUserSettings.get({
      name: 'commonSettings',
    })

    commit('setUserSettings', data)
  },

  async updateUserSettings({ state, commit }, payload) {
    const body = {
      name: 'commonSettings',
      data: {
        ...state.userSettings?.data,
        ...payload,
      },
    }

    const { data } = await ApiUserSettings.put(body)

    commit('setUserSettings', data)
  },

  async uploadProfilePhoto({ commit }, payload) {
    const { data } = await ApiProfilePhoto.post(payload)

    commit('setProfileValue', {
      key: 'photo',
      value: data,
    })
  },

  async removeProfilePhoto({ commit }) {
    await ApiProfilePhoto.delete()

    commit('setProfileValue', {
      key: 'photo',
      value: null,
    })
  },

  async fetchEducontGrades({ commit }) {
    const { data } = await ApiEducontGrades.get()

    commit('setEducontGrades', data)
  },

  async updateGrade(context, gradeId) {
    await ApiAccountStudentEducontStream.patch(gradeId)
  },
}
