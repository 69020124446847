import { ApiClientSudentsIncomeDiagmoctics } from 'Api/rest/Api/Account/Client/Students/IncomeDiagnostics'

export default {
  async fetchIncomeDiagnostics({ commit, rootState }) {
    const { id } = rootState.client.students.currentStudent
    const { data } = await ApiClientSudentsIncomeDiagmoctics.get(id)

    commit('setTestingResults', data)
  },
}
