import ApiBase from 'ApiRest/ApiBase'

export const ApiAccountInvoiceReceive = new (class extends ApiBase {
  /**
   * Получение бесплатного счета
   *
   * @param {number} invoiceId
   * @returns {string}
   */
  post(invoiceId) {
    const url = `/account/invoice/${invoiceId}/receive`

    return this._POST(url)
  }
})()
