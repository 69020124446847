import ApiBase from 'ApiRest/ApiBase'

export const ApiDiagnosticsApplicationsHasRetake = new (class extends ApiBase {
  /**
   * @param {number} applicationId
   * @returns {string}
   */
  get(applicationId) {
    const url = `/diagnostics/applications/${applicationId}/has-retake`

    return this._GET(url)
  }
})()
