/**
 * @returns {Object}
 */
export default () => ({
  links: [
    {
      link: {
        name: 'student-showcase',
      },
      label: 'Академия',
      // TODO: i18n
      labelPath: 'Академия',
      class: 'm-showcase',
      notifications: 0,
    },
    {
      link: {
        name: 'student-showcase-subjects',
      },
      label: 'Мои курсы',
      // TODO: i18n
      labelPath: 'Мои курсы',
      class: 'm-subjects',
      notifications: 0,
    },
    {
      link: {
        name: 'student-showcase-online-lessons',
      },
      label: 'Расписание',
      // TODO: i18n
      labelPath: 'Расписание',
      class: 'm-online',
      notifications: 0,
    },
    {
      link: {
        name: 'student-notifications',
      },
      label: 'Уведомления',
      // TODO: i18n
      labelPath: 'Уведомления',
      class: 'm-notifications',
      notifications: 0,
    },
    {
      link: {
        name: 'student-support',
      },
      label: 'Поддержка',
      // TODO: i18n
      labelPath: 'Поддержка',
      class: 'm-help',
      notifications: 0,
    },
  ],
})
