import { ApiTimestamp } from 'Api/system/Timestamp'
import { ApiCoursesProduct } from 'ApiRest/Api/Courses/Product'
import { ApiCoursesProductOffers } from 'ApiRest/Api/Courses/Product/Offer'
import { ApiCoursesProductRequests } from 'ApiRest/Api/Courses/Product/Requests'
import { ApiCoursesProductOptionsStudents } from 'ApiRest/Api/Courses/Product/Students'
import { ApiCoursesPurchaseItem } from 'ApiRest/Api/Courses/Purchase/Item'
import { ApiCoursesPurchaseItemCertificate } from 'ApiRest/Api/Courses/Purchase/Item/Certificate'
import DownloadHelper from 'Helpers/DownloadHelper'

export default {
  async fetchProduct({ state, commit }) {
    const { data } = await ApiCoursesProduct.get(state.productId, {
      expand: 'canBePaidDolyame',
    })

    commit('setProduct', data)
  },

  async fetchProductPurchased({ state, commit }) {
    const { data } = await ApiCoursesPurchaseItem.get(
      state.purchaseId,
      state.purchaseItemId,
    )

    commit('setProduct', data)
  },

  async fetchProductOptions({ commit, state }) {
    const { data } = await ApiCoursesProductOffers.get(state.productId, {
      user: {
        id: state.user.id,
      },
    })

    commit('setOffers', data)

    if (data.length > 0) {
      commit('setOffer', data[0])
    }
  },

  async fetchProductOptionsGuest({ commit, state }) {
    const { data } = await ApiCoursesProductOffers.get(state.productId)

    commit('setOffers', data)

    if (data.length > 0) {
      commit('setOffer', data[0])
    }
  },

  async fetchUsers({ commit, state }) {
    const { data } = await ApiCoursesProductOptionsStudents.get(state.productId)

    commit('setUsers', data)

    if (data.length > 0) {
      commit('setUser', data[0])
    }
  },

  async fetchServerTimestamp({ commit }) {
    const { data } = await ApiTimestamp.get()

    commit('setServerTimestamp', data)
  },

  createRequest({ state }) {
    return ApiCoursesProductRequests.post({
      offerId: state.offer.id,
      consumerId: state.user.id,
    })
  },

  async fetchPurchaseItemCertificate({ state }) {
    const response = await ApiCoursesPurchaseItemCertificate.get({
      purchaseId: state.purchaseId,
      purchaseItemId: state.purchaseItemId,
    })

    DownloadHelper.downloadBlob(response)
  },
}
