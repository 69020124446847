import ApiBase from 'ApiRest/ApiBase'

export const ApiIncomeDiagnosticsExtramuralAgreement =
  new (class extends ApiBase {
    /**
     * Скачивание доп.соглашения на заочную диагностику
     *
     * @param {number} applicationId
     * @returns {Promise}
     */
    get(applicationId) {
      const config = {
        responseType: 'blob',
      }
      const url = `/diagnostics/applications/${applicationId}/extramural-agreement-pdf`

      return this._GET(url, config)
    }
  })()
