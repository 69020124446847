import { ApiShowcaseCart } from 'ApiRest/Api/Showcase/Cart'
import { ApiShowcaseCartCheckout } from 'ApiRest/Api/Showcase/Cart/Checkout'
import { ApiShowcaseCartProductsCount } from 'ApiRest/Api/Showcase/Cart/ProductsCount'
import Vue from 'vue'

import { eventBus } from '@/pubsub/bus'

const globalMessageOnProductRemoved = function ({ product }) {
  eventBus.$emit('frontend.showcase.product.productRemoved', {
    product,
  })
}

export default {
  async fetchProductsCount({ commit }) {
    const { data } = await ApiShowcaseCartProductsCount.get()

    commit('setProductsCount', data)
  },

  async fetchProducts({ commit }) {
    const { data } = await ApiShowcaseCart.get()

    commit('setProducts', data)
  },

  async addProduct({ rootState }) {
    const { user, offer } = rootState.common.product

    await ApiShowcaseCart.post({
      offer: {
        id: offer.id,
      },
      consumer: {
        id: user.id,
      },
    })
  },

  async removeProduct(context, product) {
    try {
      await ApiShowcaseCart.delete({
        offer: {
          id: product.offer.id,
        },
        consumer: {
          id: product.consumer.id,
        },
      })

      globalMessageOnProductRemoved({
        product,
      })
    } catch (error) {
      Vue.toasted.show({
        type: 'error',
        duration: 3000,
      })

      throw error
    }
  },

  async checkout({ getters, commit }) {
    const { data } = await ApiShowcaseCartCheckout.post(
      getters.checkedProducts.map((product) => ({
        id: product.offer.id,
        consumer: {
          id: product.consumer.id,
        },
      })),
    )

    commit('setPurchase', data)
  },

  async addProductByLink(context, offerId) {
    await ApiShowcaseCart.post({
      offer: {
        id: offerId,
      },
    })
  },
}
