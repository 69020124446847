/**
 * Плавный скролл наверх
 */
export default function scrollToTop() {
  // TODO: Если в будущем кол-во не поддерживаемого апи (для старых браузеров) будет увеличиваться, то подумать над добавлением Modernizr
  const supportsNativeSmoothScroll =
    'scrollBehavior' in document.documentElement.style

  supportsNativeSmoothScroll
    ? window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    : window.scrollTo(0, 0)
}
