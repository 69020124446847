import HttpStatusCodes from 'Api/const/HttpStatusCodes'
import { ApiProductManagementEventsTelegram } from 'ApiRest/Api/Products/Events/Telegram'

/**
 * @returns {Object}
 */
export async function fetchTelegramBotLink() {
  let isLinked = false
  let link = null

  try {
    const { data } = await ApiProductManagementEventsTelegram.get()

    link = data.link
  } catch (error) {
    switch (error?.response?.status) {
      case HttpStatusCodes.Conflict:
        isLinked = true
        break

      default:
        throw error
    }
  }

  return {
    isLinked,
    link,
  }
}

/**
 * @returns {Object}
 */
export async function unlinkTelegramBot() {
  let link

  try {
    const { data } = await ApiProductManagementEventsTelegram.delete()

    link = data.link
  } catch {
    link = null
  }

  return {
    link,
  }
}
