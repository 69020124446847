import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseProductsFilters = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = `/showcase/products/filters`

    return this._GET(url)
  }
})()
