<template>
  <div class="btn-up" :class="{ visible }" @click="upHandler">
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.39607 7.70978L8 2L13.6569 7.65685"
        stroke="#332F3E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
import smoothScrollToTop from '@frontend/helpers/smoothScrollToTop'

export default {
  name: 'BtnUp',
  data: () => ({
    visible: false,
    screenHeight: 0,
  }),

  mounted() {
    this.screenHeight = document.documentElement.clientHeight
    this.onScrollHandler()
    window.addEventListener('scroll', this.onScrollHandler)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollHandler)
  },

  methods: {
    upHandler() {
      smoothScrollToTop()
    },

    onScrollHandler() {
      this.visible = window.scrollY >= this.screenHeight
    },
  },
}
</script>
