import { ApiShowcasePurchaseNotificationDocuments } from 'ApiRest/Api/Showcase/Purchase/Notification/Documents'

export default {
  async fetchNotificationDocuments({ commit }) {
    const { data } = await ApiShowcasePurchaseNotificationDocuments.get()

    commit('setNotificationValue', {
      path: 'documents',
      value: data,
    })
  },
}
