import ApiBase from 'ApiRest/ApiBase'

export const ApiIncomeDiagnosticsRetakeList = new (class extends ApiBase {
  /**
   * Список пересдач по очной диагностике
   *
   * @param {number} applicationId
   * @returns {Promise}
   */
  get(applicationId) {
    const url = `/diagnostics/applications/${applicationId}/retake-list`

    return this._GET(url)
  }
})()
