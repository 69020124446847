/**
 * @param {Object} data
 * @example
 * Before:
 * ```
 * {
 *     client: {
 *         age: 99,
 *         card: {
 *             tab1: null,
 *             tab2: null,
 *         },
 *         card2: {
 *             tab1: null,
 *             tab2: null,
 *             subcard2: {
 *                 tab1: null,
 *                 tab2: null,
 *             },
 *         },
 *     },
 *     passport: {
 *         id: null,
 *     },
 * }
 *
 * After:
 * {
 *     client: {
 *       age: 99,
 *       card: null,
 *       card2: null,
 *     },
 *     passport: null,
 * }
 * @protected
 */
const convertEmptyDataToDefaultValue = (data) => {
  const keys = Object.keys(data)

  for (const key of keys) {
    const value = data[key]

    if (typeof value === 'object' && value !== null) {
      const valueKeys = Object.keys(value)
      let isEmpty = true

      for (const valueKey of valueKeys) {
        if (typeof value[valueKey] === 'object' && value[valueKey] !== null) {
          const tempValue = {
            [valueKey]: value[valueKey],
          }

          convertEmptyDataToDefaultValue(tempValue)

          value[valueKey] = tempValue[valueKey]
        }

        if (value[valueKey] !== null && value[valueKey] !== '') {
          isEmpty = false
        }
      }

      if (isEmpty) {
        data[key] = null
      }
    }
  }
}

export default convertEmptyDataToDefaultValue
