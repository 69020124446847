import { getSearchParam } from 'Helpers/searchParams'

/**
 * Редирект на страницу микросервиса авторизации
 *
 * @param {string} url
 * @param {string|null} [target]
 */
export const redirectToAuth = (url, target = null) => {
  window.location.href = target ? `${url}?targetUrl=${target}` : url
}

/**
 * Редирект на админку
 *
 * @param {string} path
 */
export const redirectToAdminArea = (path) => {
  window.location.href = path
    ? `//${window.domains.admin}/${path}`
    : `//${window.domains.admin}`
}

/**
 * Редирект на ЛК
 */
export const redirectToUserArea = () => {
  window.location.href = `//${window.domains.main}`
}

/**
 * Редирект на origin
 */
export const redirectToOrigin = () => {
  window.location.href = window.location.origin
}

// TODO: Удалить после перехода на МСА
export const redirectAfterLogin = () => {
  const targetUrl = getSearchParam('targetUrl')

  if (targetUrl !== null) {
    window.location.href = targetUrl
  } else {
    redirectToOrigin()
  }
}
