import ApiSubdomainList from 'ApiRest/Api/School/UnionSubDomain'
import { mainSchool } from 'Helpers/const/AppData'
import { isShowcaseDomain } from 'Helpers/domain'
import { insertTrackersInDOM } from 'Helpers/trackers'
import schoolScripts from 'Helpers/trackers/scripts'

const getSchoolId = async () => {
  const domain = window.domains.main
  let { id } = mainSchool

  try {
    const { data: subdomainsList = [] } = await ApiSubdomainList.get(domain)

    if (subdomainsList.length) {
      const school = subdomainsList.find((subdomain) => subdomain.self)

      if (school) {
        id = school.schoolId
      }
    }

    return id
  } catch (error) {
    return null
  }
}

export const initTrackers = async () => {
  const shouldLoadTrackers = process.iblsEnv.NODE_ENV === 'production'

  if (shouldLoadTrackers) {
    let data

    if (isShowcaseDomain) {
      data = {
        metrika: {
          id: 91075504,
          init: {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            ecommerce: 'dataLayer',
          },
        },
        idAnalytics: 'G-JG6CERCZRV',
      }
    } else {
      const schoolId = await getSchoolId()

      data = schoolScripts[schoolId]
    }

    if (data && Object.keys(data).length > 0) {
      insertTrackersInDOM(data)
    }
  }
}
