import { ApiSudentIncomeDiagmocticsConfirmAcquaintance } from 'Api/rest/Api/Account/Student/Diagnostics/ConfirmAcquaintance'
import { ApiSudentIncomeDiagmoctics } from 'Api/rest/Api/Account/Student/Diagnostics/IncomeDiagnostics'

export default {
  async fetchIncomeDiagnostics({ commit }) {
    const { data } = await ApiSudentIncomeDiagmoctics.get()

    commit('setTestingResults', data)
  },

  confirmAcquaintance() {
    return ApiSudentIncomeDiagmocticsConfirmAcquaintance.put()
  },
}
