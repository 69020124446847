import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesPurchaseItemDocument = new (class extends ApiBase {
  /**
   * Сгенерированный документ продукта (договор, оферта), заполненный данными клиента
   *
   * @param {Object} params
   * @param {number} params.purchaseId
   * @param {number} params.purchaseItemId
   * @param {number} params.documentTemplateId
   * @returns {Promise}
   */
  get({ purchaseId, purchaseItemId, documentTemplateId }) {
    const url = `/courses/purchase/${purchaseId}/item/${purchaseItemId}/document/${documentTemplateId}`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
