<template>
  <LoaderFullScreen v-if="isLoading" />

  <LayoutBase
    v-else
    :is-loading-switch-student="isLoadingSwitchStudent"
    :main="main"
    :noticed="isCurrentStudentDemo"
  >
    <template #header>
      <TheHeaderClient
        :abbreviation="nameAbbreviation"
        :avatar="avatar"
        :current-student="currentStudent"
        :full-name="fullNameWithShortLastName"
        :has-contracts-or-drafts="hasContracts"
        :has-enrolled-students="hasEnrolledStudents"
        :links="getLinks"
        :notice-additional-message="getCurrentStudentSchoolDemoMessage"
        :notice-text="demoAccessTimeText"
        :rating="studentRating"
        :credit="studentCredit"
        :school-name="schoolName"
        :students="enrolledStudents"
        @select-student="currentStudentChange"
      />
    </template>

    <template #aside>
      <TheAside
        v-if="hasLinks"
        :links="getLinks"
        :user-settings="userSettings"
        @toggle-aside="toggleAside"
      />
    </template>

    <template #tabs-nav>
      <TabsNav
        :current-tab="currentTab"
        :tabs="mainTabsFiltered"
        @change="onChangeTabNav"
      />
    </template>

    <transition mode="out-in" name="fade">
      <router-view />
    </transition>

    <template #footer>
      <TheFooter :school="school" :user-id="userId" />
    </template>

    <BtnUp />
  </LayoutBase>
</template>

<script>
import TabsNav from '@frontend/components/common/TabsNav.vue'
import getEstimatedTime from '@frontend/helpers/getEstimatedTime'
import { mainTabsClient } from '@frontend/helpers/mainTabs'
import LayoutBase from '@frontend/layouts/common/main/LayoutBase.vue'
import TheAside from '@frontend/layouts/common/TheAside.vue'
import TheFooter from '@frontend/layouts/common/TheFooter.vue'
import TheHeaderClient from '@frontend/layouts/common/TheHeaderClient.vue'
import BtnUp from '@frontend/ui/BtnUp.vue'
import LoaderFullScreen from 'CommonComponents/LoaderFullScreen.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

const INTERVAL_TIME = 60000 // 1min

export default {
  name: 'ClientMain',

  components: {
    BtnUp,
    LayoutBase,
    LoaderFullScreen,
    TabsNav,
    TheAside,
    TheFooter,
    TheHeaderClient,
  },

  /**
   * @param {Object} to
   * @param {Object} from
   * @param {Function} next
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.fetchActiveModules())
  },

  data() {
    return {
      main: false,
      isLoading: false,
      demoAccessTimeText: '',
      demoTimer: null,
      demoTimerSecondsLeft: 0,
    }
  },

  computed: {
    ...mapState('client/students', [
      'currentStudent',
      'isLoadingSwitchStudent',
      'school',
      'students',
    ]),

    ...mapState('client/profile', ['profile', 'userSettings']),

    ...mapState('client/main', ['currentTab']),

    userId() {
      return this.profile.id
    },

    avatar() {
      return this.profile.photo?.thumbs?.small
    },

    schoolName() {
      return this.school.name
    },

    ...mapGetters('client/profile', [
      'fullNameWithShortLastName',
      'nameAbbreviation',
    ]),

    ...mapGetters('client/main', ['getLinks']),

    hasLinks() {
      return this.getLinks.length > 0
    },

    ...mapGetters('client/students', [
      'educationType',
      'enrolledStudents',
      'getCurrentStudentDemoAccessTime',
      'getCurrentStudentSchoolDemoMessage',
      'hasEnrolledStudents',
      'isCurrentStudentDemo',
      'isStudentEnrolledAndHasDiagnostics',
    ]),

    ...mapGetters('client/contracts', ['hasContracts']),

    ...mapGetters('common/module', ['hasModules']),

    studentRating() {
      return this.currentStudent?.rating
    },

    studentCredit() {
      return this.currentStudent?.credit
    },

    mainTabsFiltered() {
      return Object.fromEntries(
        Object.entries(mainTabsClient).filter(([, value]) => {
          if (value.educationTypes === undefined) {
            return true
          }

          return value.educationTypes.includes(
            this.currentStudent.educationType.id,
          )
        }),
      )
    },
  },

  watch: {
    /**
     * layout на главной странице отличается от второстепенных страниц по структуре и передается Boolean соответствующий
     */
    $route: {
      immediate: true,
      handler() {
        const mainPaths = ['client-main']

        this.main = mainPaths.some((path) => this.$route.name === path)
      },
    },

    /**
     * @description при смене текущего студента
     */
    currentStudent() {
      if (this.isCurrentStudentDemo) {
        this.setTimerForDemoText()
      } else {
        this.releaseTimerForDemoText()
      }
    },
  },

  created() {
    this.init()
  },

  beforeDestroy() {
    this.stopPollingMessages()
  },

  methods: {
    ...mapActions({
      fetchActiveModules: 'common/module/fetchActiveModules',
      fetchSchoolPlatform: 'client/profile/fetchSchoolPlatform',
      fetchStudents: 'client/students/fetchStudents',
      fetchTheStudent: 'client/students/fetchTheStudent',
      fetchUserSettings: 'client/profile/fetchUserSettings',
      updateUserSettings: 'client/profile/updateUserSettings',
    }),

    ...mapActions('client/messages', [
      'startPollingMessages',
      'stopPollingMessages',
    ]),

    ...mapActions('client/invoices', ['fetchInvoices']),

    ...mapActions('client/contracts', ['fetchContracts', 'fetchDrafts']),

    ...mapMutations({
      setIsLoadingSwitchStudent: 'client/students/setIsLoadingSwitchStudent',
    }),

    ...mapMutations('client/main', ['setCurrentTab']),

    async init() {
      this.isLoading = true

      try {
        // fetchSchoolPlatform - эндпоинт вызываемый, экшном возвращает 403, так как не обрабатывает локальные домены. Может еще при каких-то обстоятельствах возвращает 403.

        await Promise.allSettled([
          this.fetchUserSettings(),
          await this.fetchStudents(),
          this.fetchSchoolPlatform(),
          this.fetchInvoices(),
          this.fetchDrafts(),
          this.fetchContracts(),
        ])

        if (this.hasModules(['chat'])) {
          this.startPollingMessages()
        }

        if (!this.hasEnrolledStudents && !this.educationType.fullTime) {
          if (this.$route.name === 'client-main') {
            if (this.hasContracts) {
              await this.$router.replace({
                name: 'client-contracts',
              })
            } else {
              await this.$router.replace({
                name: 'client-request-new',
              })
            }
          }
        }

        if (
          this.isStudentEnrolledAndHasDiagnostics &&
          this.$route.name === 'client-main'
        ) {
          await this.$router.replace({
            name: 'client-contracts',
          })
        }
      } finally {
        this.isLoading = false
      }
    },

    getTextForDemo() {
      this.demoTimerSecondsLeft -= INTERVAL_TIME / 1000
      const estimatedTime = getEstimatedTime(this.demoTimerSecondsLeft)

      // TODO: i18n
      this.demoAccessTimeText = estimatedTime
        ? `Демо-доступ завершится через ${estimatedTime}.`
        : `Демо-доступ завершился.`
    },

    setTimerForDemoText() {
      this.demoTimerSecondsLeft = this.getCurrentStudentDemoAccessTime
      this.getTextForDemo()
      this.demoTimer = setInterval(this.getTextForDemo, INTERVAL_TIME)
    },

    releaseTimerForDemoText() {
      clearInterval(this.demoTimer)
      this.demoAccessTimeText = ''
      this.demoTimer = null
    },

    toggleAside(isAsideOpen) {
      this.updateUserSettings({
        isAsideOpen,
      })
    },

    async currentStudentChange(studentId) {
      this.setIsLoadingSwitchStudent(true)
      try {
        await this.fetchTheStudent(studentId)
      } finally {
        this.setIsLoadingSwitchStudent(false)
      }
    },

    onChangeTabNav(tabId) {
      this.setCurrentTab(tabId)
    },
  },
}
</script>
